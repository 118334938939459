@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;


.NewsArticle {
  @include mixin.getFlexDisplay(column, wrap, center, flex-start, 1);
  @include mixin.getFillAvailableWidth();

  &Header {
    >h1 {
      width: 100%;
    }
  }
}
