@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.IconButton {
  @include mixin.getFlexDisplay(row, wrap, center, center);
  border-radius: 0.25em;
  padding: 0.3em;
  border: 0.0625em solid transparent;
  user-select: none;
  align-self: center;
  background-color: rgb(0 0 0 / 2%);
  box-shadow: 0px 2px 5px 1px rgba(53, 39, 39, 0.2), inset 0px 1px 2px 0px rgb(38 21 3 / 60%);
  transition: all 0.3s ease-in-out;

  >span[class*="material-symbols-outlined"] {
    font-size: 2em;
    @include mixin.getFontVariationSettings(0, 600);
    color: appProp.$woodColor80;
    padding: 0.05em;
    border: 2px groove transparent;
    border-radius: 2px;
    box-shadow: 0px 1px 5px 2px appProp.$black35;
  }

  &[class*='disabled'] {
    filter: contrast(0.75);
  }

  &:active(:not([class*='disabled'])) {
    box-shadow: 0px 2px 5px 1px rgb(233 233 233 / 20%), inset 0px 3px 4px 0px rgb(38 21 3 / 60%);
    transition: all 0.3s ease-in-out;
    background-color: rgb(0 0 0 / 5%);
    @include mixin.getFontVariationSettings(1, 600);
  }

  &[class*='small'] {
    background-color: rgb(0 0 0 / 1%);
    padding: 0.25em;

    >span[class*="material-symbols-outlined"] {
      font-size: 1.5em;
      box-shadow: 0px 1px 3px 1px appProp.$black25;
    }
  }

  &[class*='large'] {
    background-color: rgb(0 0 0 / 1%);
    padding: 0.5em;

    >span[class*="material-symbols-outlined"] {
      font-size: 4em;
      box-shadow: 0px 1px 3px 1px appProp.$black25;
    }
  }
}

.LinkButtonSize[class*='small'] {
  >div[class*='IconButton'] {
    box-shadow: 0 2px 5px 1px rgb(233 233 233 / 20%), inset 0 1px 2px 0 rgb(38 21 3 / 60%);
    transition: all 0.3s ease-in-out;
    background-color: rgb(0 0 0 / 1%);
    padding: 0.25em;

    >span[class*="material-symbols-outlined"] {
      font-size: 1.5em;
    }
  }
}

.LinkButtonSize[aria-disabled="true"] {
  cursor: auto;
}

.IconButton:not([class*='disabled']) {
  cursor: pointer;

  @media (hover) {
    &:hover {
      background-color: rgb(0 0 0 / 5%);
      box-shadow: 0px 2px 5px 1px rgb(233 233 233 / 20%), inset 0px 1px 2px 1px rgb(38 21 3 / 60%);
      transition: all 0.3s ease-in-out;

      >span[class*="material-symbols-outlined"] {
        color: appProp.$woodColor70;
        @include mixin.getFontVariationSettings(1, 500);
        box-shadow: 0px 0px 2px 2px appProp.$black25;
        background-color: rgb(0 0 0 / 3%);
      }
    }
  }

  &:active {
    background-color: rgb(0 0 0 / 5%);
    box-shadow: 0px 2px 5px 1px rgb(233 233 233 / 20%), inset 0px 2px 2px 0px rgb(38 21 3 / 30%);
    transition: all 0.3s ease-in-out;

    >span[class*="material-symbols-outlined"] {
      color: appProp.$woodColor70;
      @include mixin.getFontVariationSettings(1, 500);
      box-shadow: 0px 0px 1px 1px appProp.$black15;
      background-color: rgb(0 0 0 / 3%);
    }
  }
}