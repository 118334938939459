@use '/src/scss/mixins.module.scss' as mixin;

.QuestionsDemo {
  &Display {
    @include mixin.getFillAvailableWidth();
    min-width: 16em;
    margin-bottom: 0.15em;
    position: relative;

    @media only screen and (max-width: 864px) {
      min-width: 25.5em;
      min-height: 16em;
    }

    article {
      @include mixin.getFlexDisplay(column);
      @include mixin.getFillAvailableWidth();
      gap: 0.25em;
    }

    &Spacer {
      margin-top: 1em;
    }
  }

  &Column {
    @include mixin.getFlexDisplay(column, wrap, flex-start, center, 1);
    @include mixin.getFillAvailableWidth();
    gap: 1em;
  }

  &Row {
    @include mixin.getFlexDisplay(row, wrap, center, space-evenly, 1);
    @include mixin.getFillAvailableWidth();
    gap: 2em;

    &TopSpacer {
      margin-top: 2em;
    }
  }
}

.QuestionsDemoDisplay {
  article {
    .QuestionsDemoText {
      font-size: 1.5em;
    }
  }

  section {
    margin: 1em 0;
  }
}

.AlignCenter {
  align-self: center;
}