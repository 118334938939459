@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

$maxHeight: 35em;

.DebugButton {
  position: relative;
  @include mixin.getFlexDisplay(column, wrap, flex-end, flex-start);

  &Active {
    @include mixin.getFontVariationSettings(1);
  }
}

[id='DebugPopupId'] {
  top: 3.7em;
  min-width: 25em;
}

.DebugPopup {
  overflow: hidden;
  max-height: $maxHeight;

  &BodyPanel {
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start, 1);
    overflow-y: scroll;
    max-height: calc($maxHeight - 5em);
    max-width: 30em;
    min-width: -webkit-fill-available;
    min-width: -moz-available;
    background-color: rgb(181 135 77 / 30%);
    gap: 1em;
  }
}


.DebugInfo {
  align-self: center;
  position: absolute;
  left: 50%;

  [id="FilterPopupId"] {
    max-width: 30em;
    width: max-content;
    height: $maxHeight;

    >div[class*="Popup_PopupBody"] {
      max-width: inherit;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: scroll;
    }
  }
}

.CourseCard {
  @include mixin.getFlexDisplay(column, unset, unset, unset);
  position: relative;
  user-select: none;
  box-shadow: appProp.$cellShadow;
  background-color: appProp.$cellBackground;
  border-radius: 0.3125em;
  padding: 0.5em;
  gap: 0.75em;

  &Border {
    @include mixin.getFillAvailableWidth();
    border-radius: 0.25em;
    box-shadow: appProp.$blackBoxShadow35;
    padding: 0.15em;
    margin: 0.25em 0.5em 0.25em 0.25em;
    background-color: appProp.$woodSandColor35;
  }
}

.ElevatedPanel {
  @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start, 1);
  @include mixin.getFillAvailableWidth();
  @include mixin.getRaisedPanel();
  padding: 0.25em 0.25em;
  position: relative;
  background-color: appProp.$lightgoldColor;

  h1[class*='sma'] {
    align-items: center;
    line-height: 1.25em;

    >[class*='material-symbols-outlined'] {
      color: transparent;
      font-size: 1.25em;
      line-height: 1em;
      @include mixin.getFontVariationSettings(1, 600);
    }
  }

}

.Questions {
  @include mixin.getFlexDisplay(row, nowrap, center, flex-start, 1);
  @include mixin.getFillAvailableWidth();
  gap: 0.35em;
}

.LessonPanel {
  @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start, 1);
  @include mixin.getFillAvailableWidth();
  gap: 0.2em;
}

.IconSpot {
  @include mixin.getFlexDisplay(row, nowrap, center, center, 0);

  &Question {
    background-color: appProp.$lightwoodColor;

    h1[class*='sma'] {
      font-size: 1.1em;
      border-radius: 0.25em;
      box-shadow: 0 0 0.3125em 0.0625em rgba(233, 233, 233, 0.4),
        0 0 0.1em 0 rgba(0, 0, 0, 0.65);

      >[class*=material-symbols-outlined] {
        padding: 0.1em;
      }
    }
  }
}

.TitleSpot {
  padding-left: 0.5em;
  padding-right: 0.5em;
  @include mixin.getFlexDisplay(row, nowrap, center, space-between, 1);
  gap: 0.5em;
}

.CardRow {
  @include mixin.getFlexDisplay(row, nowrap, flex-start, flex-start, 1);
  @include mixin.getFillAvailableWidth();
  gap: 0.5em;
  min-height: 1.5em;

  &[class*="Course"]>.ElevatedPanel {
    background-color: appProp.$lightwoodColor;
  }

  &[class*="Lesson"]>.ElevatedPanel {
    background-color: appProp.$forestgreenColor;
  }

  &[class*="Tutorial"]>.ElevatedPanel {
    background-color: appProp.$seagreenColor;
  }

  &[class*="Quiz"]>.ElevatedPanel v {
    background-color: appProp.$lightgoldColor;
  }
}

.CarouselOverrideCls {
  max-width: 20em;

  >:first-child {
    min-height: 2em;
  }
}

.ButtonRow {
  @include mixin.getFlexDisplay(row, nowrap, flex-start, flex-start, 1);
  @include mixin.getFillAvailableWidth();
  gap: 0.5em;
  margin: 0.25em 0.5em;
  padding-bottom: 0.75em;
}

.OpenCloseButton {
  @include mixin.getFlexDisplay(column, nowrap, center, center);
  cursor: pointer;
}

@media (hover) {
  .OpenCloseButton:hover {
    background-color: appProp.$black10;
  }
}

.Status {
  border-radius: 0.25em;
  @include mixin.getFontVariationSettings(1, 600);

  h1[class*='sma'] {
    font-size: 1.25em;
  }

  &Active>h1 {
    @extend .Status;
    background-color: rgb(187 67 0);
  }

  &Complete>h1 {
    @extend .Status;
    background-color: rgb(32 136 11);
  }

  &Locked>h1 {
    @extend .Status;
    background-color: appProp.$secondaryColor;
  }
}

@keyframes slide-in-blurred-top {
  0% {
    transform: translateY(-75px) translateX(-50%);
    opacity: 1;
  }

  100% {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
}

@keyframes slide-out-blurred-top {
  0% {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }

  100% {
    transform: translateY(-75px) translateX(-50%);
    opacity: 1;

  }
}