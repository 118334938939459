@use '/src/scss/mixins.module.scss' as mixin;

.Breadcrumbs {
  &Wrapper {
    @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start);
    @include mixin.preventImageSelection();
    margin: 0 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    >hr {
      margin-top: 0;
    }
  }

  &Display {
    @include mixin.getFlexDisplay(row, unset, center, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    min-height: 3.8em;
    position: relative;
  }

}

.InsetIconStyle {

  >span[class*="material-symbols-outlined"] {
    color: inherit;
    line-height: initial;
    padding: 0 0.05em;
  }
}

.CrumbWrapper {
  @include mixin.getFlexDisplay(row, nowrap, center, flex-start);
  gap: 0.25em;
}

/*****************************
Pixel 6a
*****************************/
@media only screen and (max-width: 428px) {
  .BreadcrumbsWrapper {
    font-size: 0.75em;
    line-height: 1.5em;
    height: 5.5em;
  }
}