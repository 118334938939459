@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Carousel {
  @include mixin.getInsetPanel();
  @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start);
  @include mixin.getFillAvailableWidth();
  gap: 0.5em;
  flex: 0;
  position: relative;
  overflow: hidden;

  &Layout {
    @include mixin.getFillAvailableWidth();
    // transition: max-height 0.5s ease-out, transform .3s linear;
  }

  &TopHeaderRow {
    @include mixin.getFlexDisplay(row, nowrap, center, center, 1);
    @include mixin.getFillAvailableWidth();
    min-height: 2.5em;
    box-shadow: 0 1px 3px 1px #0000006e;
    border-radius: 0.25em;
    backdrop-filter: brightness(1.1);
    padding: 0 0.5em;
    transform: translateZ(0);
  }

  &Header {
    @include mixin.getFlexDisplay(row, nowrap, center, center, 1);
    @include mixin.getFillAvailableWidth();
    transform: translateZ(0);
  }

  &BodyWrapper {
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start);
    width: initial;
    gap: 0.5em;
  }

  &Body {
    @include mixin.getFlexDisplay(row, nowrap, center, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 1em;
  }

  &[class*='FULL_MODE'] {
    max-height: 28em;
    .CarouselHeader {
      min-height: 2em;
    }
  }

  &[class*='MINI_MODE'] {
    max-height: 4.5em;

    .CarouselBody {
      padding-bottom: 0.25em;
      margin-bottom: -0.25em;
    }
  }
}