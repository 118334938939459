@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.SimpleButton {
  @include mixin.getFlexDisplay(row, nowrap, center, center);
  @include mixin.preventImageSelection();
  white-space: nowrap;
  gap: 0.7em;
  cursor: pointer;

  >span[class*="material-symbols-outlined"] {
    font-size: 1.75em;
    color: appProp.$primaryColor;
    @include mixin.getFontVariationSettings(0, 600);
  }

  border-bottom: appProp.$thickWoodenBorder;
  border-bottom-color: transparent;

  &[class*='Selected'] {
    border-bottom: appProp.$thickWoodenBorder;
  }

  &:active {
    background-color: appProp.$woodAltColor15;
    box-shadow: 0 1px 4px -4px #2d2525cf;
    border-bottom-color: appProp.$woodAltColor50;

    >span[class*="material-symbols-outlined"] {
      color: appProp.$woodColor90;
    }
  }
}

@media (hover) {
  .SimpleButton {
    &:hover {
      background-color: appProp.$woodAltColor10;
      box-shadow: 0 1px 4px -4px #2d2525cf;
      border-bottom-color: appProp.$woodAltColor50;

      >span[class*="material-symbols-outlined"] {
        color: appProp.$woodColor90;
      }
    }

    &:active {
      background-color: appProp.$woodAltColor15;
      box-shadow: 0 1px 4px -4px #2d2525cf;
      border-bottom-color: appProp.$woodAltColor50;

      >span[class*="material-symbols-outlined"] {
        color: appProp.$woodColor90;
      }
    }
  }
}