@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.PassKey {
  &Panel {
    @include mixin.getFlexDisplay(column, wrap, center, flex-start, 1);
    gap: 1em;
    border-radius: 0.25em;
    margin-top: 2em;
  }

  &MessagePanel {
    @include mixin.getFlexDisplay(column, wrap, center, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    gap: 0.5em;

    &Row {
      @include mixin.getFlexDisplay(row, wrap, center, space-between, 1);
      @include mixin.getFillAvailableWidth();
      gap: 1em;
    }
  }
}

.CloseButton {
  position: absolute;
  top: 0.35em;
  right: 0.35em;
}