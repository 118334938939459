@use '/src/scss/mixins.module.scss' as mixin;

.StarCounter {
  @include mixin.getFlexDisplay(row, nowrap, center, flex-end);
  z-index: 12;

  &Pulsate {
    animation: pulsate-fwd 0.4s ease-in-out 2 both;
  }

  &Spacer {
    min-width: 1em;
  }
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}