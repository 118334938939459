@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Logo {
  @include mixin.getRaisedPanel();

  &Size {
    &16 {
      >img {
        width: 1em;
        height: 1em;
      }
    }

    &24 {
      >img {
        width: 1.5em;
        height: 1.5em;
      }
    }

    &32 {
      >img {
        width: 2em;
        height: 2em;

      }
    }

    &48 {
      >img {
        width: 2.5em;
        height: 2.5em;
      }
    }
  }

  >img {
    border-radius: 0.25em;
  }
}

.ClippedMask {
  clip-path: polygon(50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%, 0 20%);
}