@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

%Item {
  @include mixin.getFlexDisplay(row, wrap, center, center);
  padding: 0.5em 2em 0.5em 1em;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;
  &:active {
    backdrop-filter: brightness(1.15);
    border-radius: 0 0.5em 0.5em 0;
    border: 0 solid transparent;
  }

  @media (hover) {
    &:hover,
    &:active {
      backdrop-filter: brightness(1.15);
      border-radius: 0 0.5em 0.5em 0;
      border: 0 solid transparent;
      letter-spacing: 1px;
    }
  }
}

$menuWidth: var(--app-menu-width);

.Menu {
  &Display {
    @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start);
    @include mixin.preventImageSelection();
    z-index: appProp.$appMenuZ;
    position: fixed;
    max-width: 16em;
    width: fit-content;
    min-width: 12em;
    border: appProp.$extraThickWoodenBorder;
    border-radius: 0.25em 0 0 0.25em;
    opacity: 1;
    right: 0;
    top: 0.5em;
    box-shadow: -0.1875em 0.1875em 0.75em -0.0625em rgba(108, 52, 13, 0.878);
    transform: translateX(1em);
    background: url('/images/menu-bg-250x500.webp') no-repeat center center;
    background-size: cover;
  }

  &SectionRestricted {
    @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start);
    box-shadow: 0 0.125em 0.3125em 0.0625em rgba(233, 233, 233, 0.2), inset 0 0.0875em 0.45em 0.1em rgba(38, 21, 3, 0.6);
    margin: 0 2em 0.35em 0.85em;
    border-radius: 0.35em;
    background-color: #914e006e;
    padding: 0;

    [class*='MenuItem']:first-of-type {
      padding: 0.5em 1.5em 0 0.5em;
    }
    [class*='MenuItem']:last-of-type {
      padding: 0.1em 2em 0.25em 0.5em;
    }
  }

  &Open {
    animation: slide-in-left 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  }

  &Hide {
    animation: slide-in-right 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
  }

  &ItemsWrapper {
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start);
    @include mixin.getFillAvailableWidth();
    flex: 1;
    padding: 0.25em 0.75em 0.25em 0;
  }

  &Item {
    @extend %Item;

    &Active {
      @extend %Item;

      > div > div > span[class*='material-symbols-outlined'][class*='MeIcon'] {
        @include mixin.getFontVariationSettings(1);
      }

      > div > h1 {
        font-variation-settings: 'FILL' 1, 'wght' 800, 'GRAD' 200, 'optz' 48 !important;
      }
    }

    &Pending {
      @extend %Item;

      > div > h1 {
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 200, 'optz' 48 !important;
      }
    }
  }
}

.MenuHide {
  &.MenuDisplay {
    box-shadow: 0.25em 0.1875em 0.75em -0.0625em rgba(108, 52, 13, 0.878);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1em) translateZ(0);
  }

  100% {
    transform: translateX(calc(0.1em + $menuWidth)) translateZ(0);
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(calc(0.1em + $menuWidth)) translateZ(0);
  }

  100% {
    transform: translateX(1em) translateZ(0);
  }
}
