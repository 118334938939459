@use '/src/scss/mixins.module.scss' as mixin;

.News {
  &Display {
    @include mixin.getFillAvailableWidth();
    min-width: 16em;
    margin-bottom: 0.15em;

    @media only screen and (max-width: 864px) {
      min-width: 25.5em;
    }
  }

  &HeaderHandleIcon {
    text-align: right;
  }

  &Column {
    @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    gap: 1em;
  }

  &Row {
    @include mixin.getFlexDisplay(row, wrap, center, space-between, 1);
    @include mixin.getFillAvailableWidth();
    gap: 1em;
  }
}

.ActionButtons {
  @include mixin.getFlexDisplay(row, nowrap, center, flex-end, 1);
  gap: 0.5em;
}

.AlignCenter {
  align-self: center;
  @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
  margin: 0.5em 0 1em;
}
