@use '/src/scss/mixins.module.scss' as mixin;

.HeaderWithDetails {
  @include mixin.getFillAvailableWidth();
  margin-bottom: 1em;
  content-visibility: visible;

  &Summary {
    cursor: pointer;
    display: block;
    // thanks to https://justmarkup.com/articles/2020-09-22-styling-and-animation-details/
    &::-webkit-details-marker {
      display: none;
    }
    list-style: none;
  }

  &Row {
    @include mixin.getFlexDisplay(row, wrap, center, space-between);
    @include mixin.getFillAvailableWidth();
    gap: 1em;
  }

  &HelpIcon {
    position: absolute;
    right: -1.25em;
    top: 0;
  }

  &HelpIconPositionLineEnd {
    position: absolute;
    right: 0;
    top: 0;
  }
}