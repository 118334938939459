@use '/src/scss/mixins.module.scss' as mixin;

.TitleHeader {

  &Display {
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start);
    @include mixin.getFillAvailableWidth();
    @include mixin.preventImageSelection();

    hr {
      margin-top: 0;
    }
  }

  &Row {
    @include mixin.getFlexDisplay(row, nowrap, center, flex-start);
    gap: 0.25em;

    &OverflowHidden {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &OverflowVisible {
      overflow: visible;
    }

    &[class*='FillWidth'] {
      @include mixin.getFillAvailableWidth();
    }

    >span[class*="material-symbols-outlined"] {
      font-size: inherit;
      color: inherit;
    }
  }




}