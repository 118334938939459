@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;


.MeIconInfo {
  white-space: inherit;

  &PositionAtTextEnd {
    position: absolute;
    right: -1.25em;
    top: -0.5em;
  }

  &PositionAtLineEnd {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media (hover) {
  .MeIconInfo:hover {
    &>div>span[class*=material-symbols-outlined][class*=MeIcon] {
      @include mixin.getFontVariationSettings(1, 600);
    }
  }
}

.MeIconInfo:active {
  &>div>span[class*=material-symbols-outlined][class*=MeIcon] {
    @include mixin.getFontVariationSettings(1, 600);
  }
}