@use '/src/scss/mixins.module.scss' as mixin;

.FramedImageItem {
  @include mixin.getFlexDisplay(column, nowrap, center, center);
  padding: .55em;
  border-radius: .25em;
  filter: drop-shadow(0 0 1px black);

  &Large {
    min-height: 40em;
    min-width: 25em;
  }
}