@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

%Item {
  @include mixin.getFlexDisplay(row, wrap, center, center);
  padding: 0.5em 1em;
}

.MenuHandle {
  cursor: pointer;
  background-color: #8359385e;
  position: absolute;
  width: 3em;
  height: 3em;

  >span[class*="material-symbols-outlined"] {
    font-variation-settings: "FILL" 0, "wght" 500, "GRAD" 200, "opsz" 48;
    font-size: 2.4em;
    padding-left: 0.125em;
    color: rgb(255, 158, 64, 0.9);
    transition: transform ease-in 0.1s;
    transition-delay: 0.5s;
    transform: scaleX(-1);
  }

  &App {
    left: -3.375em;
    top: 0.1em;
    border: appProp.$thickWoodenBorder;
    border-radius: 0.25em 0 0 0.25em;
    border-right: none;
    z-index: calc(appProp.$appMenuZ + 1);

    &.Hide {
      >span[class*="material-symbols-outlined"] {
        transition: transform ease-in 0.1s;
        transition-delay: 0.5s;
        transform: scaleX(1);
      }
    }
  }


  &:active {
    background-color: #835938cd;

    >span[class*="material-symbols-outlined"] {
      color: rgb(255, 158, 64, 1);
      font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 200, "opsz" 48;
      opacity: 1;
    }
  }
}

@media (hover) {
  .MenuHandle:hover {
    background-color: #8359388b;

    >span[class*="material-symbols-outlined"] {
      font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 200, "opsz" 48;
      opacity: 1;
    }
  }

  .MenuHandle:active {
    background-color: #835938cd;

    >span[class*="material-symbols-outlined"] {
      color: rgb(255, 158, 64, 1);
      font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 200, "opsz" 48;
      opacity: 1;
    }
  }
}