@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Toast {
  @include mixin.getWoodMenu();
  border: appProp.$medWoodenBorder;
  @include mixin.getFlexDisplay(column, wrap, center, center);
  align-self: center;
  backdrop-filter: brightness(1.2) opacity(0.3);
  position: fixed;
  user-select: none;
  background-color: appProp.$cellBackground;
  border-radius: 0.5em;
  min-width: min(50%, appProp.$toastMinWidth);
  max-width: min(75%, appProp.$toastMaxWidth);
  width: fit-content;
  min-height: appProp.$toastMinHeight;
  margin: 0.1em;
  gap: 0.75em;
  z-index: appProp.$toastZ;
  opacity: 0;
  bottom: 90%;
  transform-origin: center;

  &Show {
    animation: slide-in-bottom 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal forwards;
  }

  &Hide {
    animation: slide-out-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s 1 normal both;
  }

  &Row {
    @include mixin.getFlexDisplay(row, wrap, center, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    gap: 1em;
    padding: 0.2em;

    [class*='warning'],
    [class*='info'],
    [class*='success'],
    [class*='error'] {
      > h1 {
        filter: drop-shadow(1px 1px 1px rgba(52, 52, 52, 0.65));

        > [class*='material-symbols-outlined'] {
          @include mixin.getFontVariationSettings(0, 700, 200, 32);
        }
      }
    }

    [class*='success'] > h1 {
      background-color: appProp.$successColor;
    }

    [class*='info'] > h1 {
      background-color: appProp.$infoColor;
    }

    [class*='warning'] > h1 {
      background-color: appProp.$warningColor;
    }

    [class*='error'] > h1 {
      background-color: appProp.$errorColor;
    }
  }

  &Message {
    @include mixin.getFlexDisplay(column, wrap, flex-start, center, 1);
    @include mixin.getFillAvailableWidth();
  }

  //#region ANIMATIONS
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(100em);
      animation-timing-function: ease-in;
      opacity: 1;
    }

    24% {
      opacity: 1;
    }

    40% {
      transform: translateY(calc(100dvh - 5.5em));
      animation-timing-function: ease-in;
    }

    65% {
      transform: translateY(calc(100dvh - 6.5em));
      animation-timing-function: ease-in;
    }

    82% {
      transform: translateY(calc(100dvh - 7.2em));
      animation-timing-function: ease-in;
    }

    93% {
      transform: translateY(calc(100dvh - 8em));
      animation-timing-function: ease-in;
    }

    25%,
    55%,
    75%,
    87% {
      transform: translateY(calc(100dvh - 7.5em));
      animation-timing-function: ease-out;
    }

    100% {
      transform: translateY(calc(100dvh - 7em));
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }

  @keyframes slide-out-bottom {
    0% {
      transform: translateY(calc(100dvh - 7em));
      opacity: 1;
    }

    7% {
      transform: translateY(calc(100dvh - 8em));
      animation-timing-function: ease-in;
      opacity: 1;
    }

    15% {
      transform: translateY(calc(100dvh - 7.5em));
      animation-timing-function: ease-in;
      opacity: 1;
    }

    23% {
      transform: translateY(calc(100dvh - 10em));
      animation-timing-function: ease-in;
      opacity: 1;
    }

    100% {
      transform: translateY(1000em);
      // opacity: 0;
    }
  }

  //#endregion ANIMATIONS
}
