@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Glass {
  @include mixin.getGlass();
  position: relative;
}

.Wood {
  @include mixin.getPopupWoodBg();
  position: relative;
  background-clip: border-box;
}

.UnderlayWrapper {
  @include mixin.getFlexDisplay(column, unset, center, center, 1);
  pointer-events: all;
  inset: 0;
  position: fixed;
  transform-origin: top;
  transition: transform 0.1s;

  // Allows popups like Filter to "hide" overlay from being seen
  &:not([class*='transparentOverlay']) {
    background-color: appProp.$black35;

    &[class*='hide'] {
      backdrop-filter: blur(2px);
    }

    &[class*='show'] {
      backdrop-filter: blur(3px);
    }
  }

  &[class*='hide'] {
    transform: scaleY(0);
    cursor: none;
  }

  &[class*='show'] {
    transform: scaleY(1);
  }
}

.Popup {
  min-width: 20em;
  max-width: calc(100vw - 2em);
  min-height: 15em;
  opacity: 0;
  position: absolute;
  border-radius: 0.75em;
  background-origin: border-box;
  transition: opacity 0.25s, transform 0.3s;

  &Header {
    position: relative;
    @include mixin.getFlexDisplay(row, nowrap, flex-start, space-between);
    @include mixin.getFillAvailableWidth();

    &Text {
      margin-left: 0.5em;
    }

  }

  &Body {
    @include mixin.getFlexDisplay(column, unset, center, center, 1);
    @include mixin.getFillAvailableWidth();
    margin: 1em;
  }

  &[class*='hide'] {
    transform: scaleY(0);
    opacity: 0;
    cursor: none;
    z-index: -1;
  }

  &[class*='show'] {
    height: auto;
    transform-origin: top;
    transform: scaleY(1);
    transition-delay: .150s;
    opacity: 1;
  }

  &HeaderBackButton {
    position: absolute !important;
    top: 0.25em;
    left: 0.25em;
  }
}

.UnderlayWrapper {
  z-index: calc(appProp.$genericPopupZ - 1);

  .Popup {
    z-index: calc(appProp.$genericPopupZ);
  }
}

.UnderlayWrapper.BlockingZCls {
  z-index: calc(appProp.$blockingPopupZ - 1);

  .Popup {
    z-index: calc(appProp.$blockingPopupZ);
  }
}

/*****************************
Pixel 6a
*****************************/
@media only screen and (max-width: 428px) {
  .Popup {
    font-size: 12px;
  }
}

/*****************************
Older Pixel 2, or 3
*****************************/
@media only screen and (max-width: 375px) {
  .Popup {
    font-size: 12px;
  }
}

/*****************************
Smallest resolution
*****************************/
@media only screen and (max-width: 320px) {
  .Popup {
    font-size: 12px;
  }
}