@use '/src/scss/mixins.module.scss' as mixin;

.CollapsibleListHandle {
  @include mixin.getFlexDisplay(row, unset, center);
  @include mixin.getFillAvailableWidth();
  // gap: 0.5em;

  &Clickable {
    cursor: pointer;
  }

  &:not([class*="CollapsibleListHandleClickable"]) {
    user-select: none;
    cursor: default;
  }

  >h1>span[class*="material-symbols-outlined"] {
    cursor: pointer;
    @include mixin.getFontVariationSettings(0, 700, 200, 24);
    margin-right: 0.25em;
  }
}