@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

.SceneLoader {
  &Display {
    @include mixin.getFlexDisplay(column, unset, center, center, 1);
    aspect-ratio: 1;
    position: relative;

    &[class*="shown"] {
      visibility: visible;
    }

    &[class*="hidden"] {
      visibility: hidden;
    }
  }

  &Rotate {
    position: absolute;
    transform-origin: center;

    &Icon[class*="material-symbols-outlined"] {
      aspect-ratio: 1;
      @include appAnimation.rotateAnimation();
    }

    &:last-child {
      transform: scaleY(-1);
    }
  }

}

.FlipAnimation {
  animation: flip-vertical-right 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.Logo {
  width: 3em;
  height: 3em;
  aspect-ratio: 1;
  @extend .FlipAnimation;
}


@keyframes flip-vertical-right {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}