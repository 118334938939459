.InsetText {
  align-items: center;
  gap: 0.2em;

  >span[class*="material-symbols-outlined"] {
    color: transparent;
    // text-indent: 0.01em;
    // line-height: 1.1em;
  }
}
