@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

$maxHeight: 35em;

.Capture {
  &Row {
    @include mixin.getFlexDisplay(row, nowrap, baseline, space-between, 1);
    @include mixin.getFillAvailableWidth();
  }

  &Info {
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    gap: 0.25em;
  }
}