@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

%UpperCaseWithShadow {
  text-transform: uppercase;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.275);
}


h1.InsetText {
  font-family: 'Big Shoulders Display', 'BigShouldersDisplay', sans-serif;
  text-shadow: 2px 2px 3px appProp.$white25;
  @include mixin.getFontVariationSettings(0, 600);
  line-height: 1.2em;
  background-clip: text;
  position: relative;

  @include mixin.getInsetColorAndFontSettings(appProp.$woodColor100);

  &[class*='Capitalize'] {
    text-transform: capitalize;
  }

  &[class*='Uppercase'] {
    text-transform: uppercase;
  }

  &[class*='Selected'] {
    @include mixin.getFontVariationSettings(0, 800);
  }

  &[class*='SingleLine'] {
    white-space: nowrap;
  }

  &[class*='Alt'] {
    background-color: appProp.$woodGreenColor95;
  }

  &[class*='Green'] {
    background-color: rgb(46 102 35 / 100%);
  }

  &[class*='Red'] {
    background-color: rgb(166 25 0 / 100%);

  }

  @include mixin.getElementFontSizes();
}