:root {
    --mdc-theme-primary: #552A00;
    --mdc-theme-secondary: $cardActiveBackground;
    --mdc-theme-background: #936F4E;
    --mdc-theme-surface: #ffa424;
    --mdc-theme-error: #b32c0c;
    --mdc-theme-on-primary: #fff;
    --mdc-theme-on-secondary: #fff;
    --mdc-theme-on-surface: #552A00;
    --mdc-theme-on-error: #fff;
    --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
    --mdc-theme-text-secondary-on-background: rgba(202, 3, 3, 0.54);
    --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
    --mdc-theme-text-secondary-on-light: rgba(230, 0, 0, 0.54);
    --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
    --mdc-theme-text-primary-on-dark: white;
    --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
    --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
    --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
    --mdc-typography-font-family: 'Big Shoulders Display', 'BigShouldersDisplay', sans-serif;
    --mdc-checkbox-unchecked-color: $primaryColor80;
    --mdc-checkbox-disabled-color: $primaryColor30;
    --mdc-typography-subtitle1-font-size: 1em;
    --mdc-checkbox-ink-color: $primaryColor80;
    --game-row-height: 4.5em;
    --app-menu-width: 15em;
}