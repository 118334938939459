@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/animations.module.scss' as appAnimation;

.HomeButton {

  >div {
    &[class*="IconRaised"]>div>a>span[class*="material-symbols-outlined"][class*="MeIcon"] {
      border: 0.35rem solid transparent;
    }
  }
}

.Wobble {
  @include appAnimation.wobbleAnimation();
}