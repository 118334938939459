@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

$fontVarUnfilled: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48;
$fontVarFilled: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;

.CompletionStar {
  @include mixin.preventImageSelection();
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 15em;
  perspective: 1000px;
  z-index: -1;


  &Contents {
    position: relative;
    @include mixin.getFlexDisplay(column, nowrap, center, center);
    @include mixin.preventImageSelection();
    font-variation-settings: $fontVarUnfilled;
    transform: scale(0);
    opacity: 0;

    .ActionButton {
      position: fixed;
      margin-top: 24em;
      opacity: 0;
      box-shadow: -0.1875em 0.1875em 0.75em -0.0625em rgb(108 52 13 / 87.8%);
    }

    &TextPanel {
      position: absolute;
      @include mixin.getPopupWoodBg();
      @include mixin.getFlexDisplay(column, nowrap, center, flex-end);
      min-width: 15em;
      width: 100%;
      height: 17em;
      border-radius: .5em;
      z-index: -1;
    }
  }

  &[class*="PLAY"] {
    z-index: 100;

    .CompletionStarContents {
      cursor: pointer;

      &.FlipOnX {
        animation: flip-x 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1 forwards;
      }

      &.SlideElliptic {
        animation: slide-in-elliptic-bottom-fwd 1.7s linear 1 forwards;
      }

      .ScaleUpAndFlip {
        animation: scale-flip-on-y 1.7s linear 1 forwards;
      }

      &TextPanel {
        animation: blur-on-enter 1s linear 1 forwards;
      }
    }
  }

  &[class*="OFF"] {
    z-index: 1;

    .CompletionStarContents {
      &.FlipOnX {
        animation: slide-out-elliptic-top-bck 0.7s ease-in both;
      }

      &.SlideElliptic {
        animation: slide-out-elliptic-top-bck 0.7s ease-in both;
      }

      &TextPanel {
        filter: blur(1px);
      }
    }
  }
}

.Star[class*="material-symbols-outlined"] {
  font-size: 12em;
  filter: drop-shadow(0 1px 1px black);
}

.StarSpot[class*="material-symbols-outlined"] {
  font-size: 12em;
  color: rgb(202 100 0 / 27%);
  background-clip: text;
}

.StarOutline {
  @extend .Star;
  color: rgb(250 162 73);
  position: relative;
  font-variation-settings: inherit;
}

.KidStar[class*="material-symbols-outlined"] {
  @extend .Star;
  color: rgb(250 176 101);
  position: absolute;
  @include mixin.getFontVariationSettings(1, 300, 0);
}

@keyframes flip-x {

  0% {
    opacity: 0;
    transform: rotateY(0deg) scale(0);
    font-variation-settings: $fontVarUnfilled;
    filter: blur(1px);
  }

  10% {
    opacity: 1;
    transform: rotateY(0deg) scale(1.20);
    filter: blur(0);
  }

  20% {
    transform: rotateY(0deg) scale(0.75);
    filter: blur(1px);
  }

  30% {
    transform: rotateY(0deg) scale(1.2);
    filter: blur(0);
  }

  40% {
    transform: rotateY(0deg) scale(1);
    font-variation-settings: $fontVarUnfilled;
    filter: blur(0);
  }

  45% {
    font-variation-settings: $fontVarUnfilled;
    filter: blur(1px);
  }

  46% {
    font-variation-settings: $fontVarFilled;
    filter: blur(1px);
  }

  70% {
    transform: rotateY(180deg);
    font-variation-settings: $fontVarFilled;
    filter: blur(0);
  }

  77% {
    font-variation-settings: $fontVarFilled;
  }

  78% {
    font-variation-settings: $fontVarUnfilled;
  }

  100% {
    opacity: 1;
    transform: rotateY(360deg);
    font-variation-settings: $fontVarUnfilled;
    filter: blur(0);
  }
}

@keyframes slide-in-elliptic-bottom-fwd {
  0% {
    transform: translateY(600px) rotateX(30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 0;
  }

  50%,
  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% -1400px;
    opacity: 1;
  }
}

@keyframes scale-flip-on-y {

  0%,
  51% {
    transform: scale(1) rotateY(0);
    transform-origin: center;
  }

  75% {
    transform: scale(2) rotateY(180deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotateY(360deg);
  }
}

@keyframes slide-out-elliptic-top-bck {
  0% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1;
  }

  100% {
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 1;
  }
}

@keyframes blur-on-enter {
  0% {
    filter: blur(3px);
  }

  100% {
    filter: blur(0);
  }
}