@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.ThumbsUp {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  &.Show {
    opacity: 1;
    z-index: 100;
    animation: bubble-up-animation 2s linear 1 forwards,
      side-to-side-animation 1s ease-in-out 4 alternate;
  }

  &.Hide {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes bubble-up-animation {
  0% {
    transform-origin: center;
    transform: scale(1);
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    margin-top: -40%;
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes side-to-side-animation {
  0% {
    margin-left: -10px;
  }

  100% {
    margin-left: 10px;
  }
}