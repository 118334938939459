@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

.DisplayDigits {
  @include mixin.getFlexDisplay(row, nowrap, center, center);
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 0.25em;
  box-shadow: appProp.$insetStyle;


  &[class*="small"] {
    gap: 0.25em;
    padding: 0;
    box-shadow: none;
  }

  &[class*="tiny"] {
    gap: 0.05em;
    padding: 0;
    box-shadow: none;
  }

  &[class*="fail"] {
    >div {
      @include appAnimation.shakeAnimationTimeboxed();
    }
  }

  &[class*="pass"] {
    >div {
      @include appAnimation.successAnimationTimeboxed();
    }
  }
}