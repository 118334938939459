@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.CardShell {
  @include mixin.getFlexDisplay(column, unset, center, center, 1);
  @include mixin.getInsetPanel();
  @include mixin.getFillAvailableWidth();
  padding: 0.5em;
  border-radius: 0.25em;
  gap: 1em;

  &Border {
    @include mixin.getFlexDisplay(column, nowrap, initial, initial, 1);
    @include mixin.getFillAvailableWidth();
    border-radius: 0.25em;
    box-shadow: appProp.$blackBoxShadow35;
    padding: 0.15em 0.15em 0.05em;
    @include mixin.getFontVariationSettings(0, 700);
    position: relative;
  }

  @include mixin.getDefaultListStyle();
}

.Clickable {
  cursor: pointer;
  transition: all 0.2s ease-in-out allow-discrete;

  @media (hover) {
    &:hover {
      @include mixin.getHighlight(1.1);
      box-shadow: appProp.$blackBoxShadow50;
    }
  }

  &:active {
    @include mixin.getHighlight(1.25);
  }
}
