@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;


.MeToggle {
  @include mixin.getFlexDisplay(column, wrap, center, space-between);
  @include mixin.getFillAvailableWidth();

  &Row {
    @include mixin.getFlexDisplay(row, nowrap, center, center);
    @include mixin.getFillAvailableWidth();

    [class*="MeIcon"] {
      @include mixin.preventImageSelection();
    }
  }

  &HRuleTop {
    flex-direction: column-reverse;
  }

  &HRuleBottom {
    flex-direction: column;
  }

  .MeToggleRow.MeToggleBtnHidden {
    div[class*="MeIconRaised"] {
      opacity: 0;
      visibility: hidden;
      cursor: default;
      @include mixin.preventImageSelection();
    }
  }
}


details.HeaderDetailsStyle {
  margin-bottom: 0;
}