@use '/src/scss/mixins.module.scss' as mixin;

.CareerHub {
  &ButtonRow {
    @include mixin.getFlexDisplay(row, nowrap, center, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    gap: 0.5em;
  }

  &Row {
    @include mixin.getInsetPanel();
    @include mixin.getFlexDisplay(row, nowrap, center, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    margin-bottom: 0;
    gap: 0.5em;
    position: relative;

    >h1 {
      min-width: fit-content;
    }
  }
}