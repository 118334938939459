@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

.Crest {
  &Display {
    @include mixin.getFlexDisplay(row, wrap, center, center);
    width: fit-content;
    position: relative;
  }

  // Images *****************************************
  &Image {
    @include mixin.getFontVariationSettings(0, 700);
    width: appProp.$size1875;
    height: appProp.$size1875;
    @include mixin.preventImageSelection();
  }

  &Blurred {
    filter: blur(3px);
    .CrestStars {
      opacity: 0;
    }
  }

  //#region Text *******************************************
  &Text[class*='quiz'] {
    @include mixin.getFlexDisplay(row, nowrap, center, center);
    position: absolute;
    user-select: none;
  }

  &Text[class*='quiz'] {
    bottom: 1.2em;
  }

  &Text[class*='lesson'] {
    bottom: 1.9em;
    top: calc(50% - 0.5em);
  }

  &Text[class*='course'] {
    bottom: 1.6em;
    top: calc(50% - 0.5em);
  }

  //#endregion
}

.CrestDisplay {

  // Text (increasing specificity)
  .CrestText {
    position: absolute;
    z-index: 2;
    font-size: 0.9em;
    top: 10%;
  }

  .CrestTextAbsolute {
    position: absolute;
    z-index: 2;
    font-size: 1.5em;
  }

  //#region Sizes ******************************************
  &[class*='size165'] {
    .CrestImage {
      width: appProp.$size165;
      height: appProp.$size165;
    }
  }

  &[class*='size300'] {
    .CrestImage {
      width: appProp.$size300;
      height: appProp.$size300;
    }
  }

  &[class*='size660'] {
    .CrestImage {
      width: appProp.$size660;
      height: appProp.$size660;
    }
  }

  &[class*='size700'] {
    .CrestImage {
      width: appProp.$size700;
      height: appProp.$size700;
    }
  }

  &[class*='size800'] {
    .CrestImage {
      width: appProp.$size800;
      height: appProp.$size800;
    }
  }

  &[class*='size1600'] {
    .CrestImage {
      width: calc(appProp.$size1600 - 2em);
      height: appProp.$size1600;
    }
  }

  &[class*='size1875'] {
    .CrestImage {
      width: calc(appProp.$size1875 - 2em);
      height: appProp.$size1875;
    }
  }

  &[class*='size2500'] {
    .CrestImage {
      width: calc(appProp.$size2500 - 2em);
      height: appProp.$size2500;
    }
  }

  &[class*='size3500'] {
    .CrestImage {
      width: calc(appProp.$size3500 - 2em);
      height: appProp.$size3500;
    }
  }

  &[class*='size3600'] {
    .CrestImage {
      width: 28em;
      height: appProp.$size3600;
    }
  }

  //#endregion

  //#region States *****************************************
  &[class*='not-selected'],
  &[class*='is-selected'],
  &[class*='presentation'],
  &[class*='active'] {
    cursor: pointer;
  }

  &[class*='active'] {
    .CrestImage {
      // filter: brightness(1.5);
      filter: brightness(1.1) drop-shadow(0 0 .625em black);
    }
  }

  &[class*='awaiting'] {
    .CrestImage {
      filter: opacity(0.90);

      &::after {
        content: "⌛";
        font-size: 2.5em;
        color: #81b603;
        @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
        position: relative;
        top: 0.75em;
      }
    }
  }

  &[class*='locked'] {
    .CrestImage {
      filter: opacity(0.75);

      &::after {
        content: "🔒";
        font-size: 2.5em;
        color: #81b603;
        @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
        position: relative;
        top: 0.75em;
      }
    }
  }

  &[class*='completed'] {
    .CrestImage {
      opacity: 1;
      filter: brightness(1.15);

      &::after {
        content: "✓";
        font-size: 3em;
        color: #81b603;
        @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
        position: relative;
        top: 1em;
      }
    }
  }

  &[class*='not-selected'] {
    .CrestImage {
      filter: brightness(1.15);
      scale: 0.85;

    }
  }

  &[class*='is-selected'] {
    .CrestImage {
      opacity: 1;
      filter: brightness(1.35);
    }
  }

  &[class*='presentation'] {
    .CrestImage {
      opacity: 1;
      filter: brightness(1.25) drop-shadow(0 0 .625em black);
    }
  }

  //#endregion

  // ACTIONS *****************************************
  &[class*='active']:active {
    .CrestImage {
      filter: drop-shadow(0 0.187em 0.625em appProp.$black25) brightness(1.7);
    }
  }
}

//#region ACTION *****************************************
@media (hover) {

  .CrestDisplay[class*='is-selected'] {
    &:hover {
      .CrestImage {
        filter: drop-shadow(0 0.1em 0.125em appProp.$black25) brightness(1.35);
      }
    }
  }

  .CrestDisplay[class*='not-selected'] {
    &:hover {
      .CrestImage {
        filter: drop-shadow(0 0 0.025em rgba(0, 0, 0, 0.85)) brightness(1.15);
        scale: 0.95;
      }
    }
  }

  .CrestDisplay[class*='active'] {
    &:hover {
      .CrestImage {
        filter: brightness(1.2) drop-shadow(0 0 0.05em rgb(66, 66, 66));
      }
    }

    &:active {
      .CrestImage {
        filter: drop-shadow(0 0.187em 0.125em rgba(0, 0, 0, 0.05)) brightness(1.25);
      }
    }
  }

  .CrestDisplay[class*='[presentation]'] {

    &:active,
    &:hover {
      .CrestImage {
        filter: drop-shadow(0 0 .025em black) brightness(1.25);
      }
    }
  }

  .CrestDisplay[class*='is-selected'],
  .CrestDisplay[class*='not-selected'] {
    &:active {
      .CrestImage {
        filter: drop-shadow(0 0 0.25em appProp.$black25) brightness(1.7);
      }
    }
  }
}
// #endregion

.Crest {
  &Wrapper {
    perspective: 1000px;
    transform-style: preserve-3d;
    @include mixin.preventImageSelection();
  }

  &Children {
    position: absolute;
    z-index: 2;
    top: 32%;
    left: 50%;
    transform: translateX(-50%);
  }

  &Stars {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    @include mixin.getFlexDisplay(row, nowrap, center, center, 1);

    >h1>span[class*=material-symbols-outlined] {
      @include mixin.getFontVariationSettings(1, 700);
    }
  }

  &Contents {
    opacity: 0;
    @include mixin.preventImageSelection();
    @include appAnimation.flipOnX();
  }
}