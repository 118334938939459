@use 'variables.module.scss' as appProp;

//#region ANIMATIONS ********************************************************************
@mixin rotateAnimation() {
  animation: rotateEffect 1.25s linear infinite;
}

@mixin shakeAnimationTimeboxed() {
  animation: fail-animation 0.35s 5;
  background-color: appProp.$resultFailColor;
  filter: blur(1px);
}

@mixin successAnimationTimeboxed() {
  animation: pass-animation 0.35s 5;
  background-color: appProp.$resultPassColor;
  filter: blur(1px);
}

@mixin shakeAnimation() {
  animation: fail-animation 0.35s infinite;
  background-color: appProp.$resultFailColor;
  filter: blur(1px);
}

@mixin failShakeAnimation() {
  animation: fail-shake-animation 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2 both;
  background-color: appProp.$resultFailColor;
  filter: blur(2px);
}

@mixin blinkAnimation() {
  animation: 0.5s blinking 0s 3;
  background-color: rgba(255, 0, 0, 0.15);
  filter: blur(2px);
}

@mixin successAnimation() {
  animation: pass-animation 0.35s infinite;
  background-color: appProp.$resultPassColor;
  filter: blur(1px);
}

@mixin hoverAnimation() {
  animation: 0.5s hoverEffect 0s 1 forwards;
}

@mixin popupAnimation() {
  animation: popupEffect 2s 1 cubic-bezier(0.28, 0.35, 0, 0.86) forwards;
}

@mixin starsAroundMoleHeadAnimation($duration: 2s) {
  animation: followpath $duration linear infinite forwards;
}

@mixin fadeInAnimation($delay: 1s) {
  animation: fade-in 0.5s 1 ease-in $delay both;
}

@mixin fadeInAnimation70($delay: 1s) {
  animation: fade-in-70 1s 1 ease-in $delay both;
}

@mixin flipOnX() {
  animation: flip-x 0.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s forwards;
}

@mixin flipOnXBack() {
  animation: flip-x-back 0.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s forwards;
}

@mixin flipOnY($direction: forwards) {
  @if ($direction ==forwards) {
    animation: flip-y-forward 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s both;
  }

  @else {
    animation: flip-y-backward 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s both;
  }
}

@mixin wobbleAnimation() {
  animation: jello-horizontal 9s infinite both;
}

//#endregion ANIMATIONS

//#region KEYFRAMES *********************************************************************
@keyframes bounce {
  0% {
    transform: translateY(0%);
  }

  20% {
    transform: translateY(-25%);
  }

  30% {
    transform: translateY(0%);
  }

  35% {
    transform: translateY(-13%);
  }

  37% {
    transform: translateY(0%);
  }

  39% {
    transform: translateY(-3%);
  }

  40% {
    transform: translateY(0);
  }

  70% {
    transform: translateY(0%);
  }

  85% {
    transform: translateY(-15%);
  }

  90% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes pass-animation {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  25%,
  75% {
    transform: translateY(-10px);
  }
}

@keyframes fail-animation {

  0%,
  91%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  25%,
  75% {
    transform: translateX(-10px);
  }
}

@keyframes fail-shake-animation {

  0%,
  91%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    transform: translateX(10px);
  }

  80% {
    transform: translateX(8px);
  }

  90% {
    transform: translateX(-8px);
  }
}

@keyframes shake2 {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

@keyframes blinking {
  0% {
    background-color: rgb(8, 171, 8);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    background-color: rgb(2, 219, 2);
  }

  20%,
  40%,
  60%,
  80%,
  100% {
    background-color: rgb(79, 215, 79);
  }
}

@keyframes hoverEffect {
  0% {
    background-color: appProp.$woodAltColor36;
  }

  10% {
    background-color: appProp.$woodAltColor36;
  }

  80% {
    background-color: appProp.$woodAltColor36;
  }

  100% {
    // background-color: transparent;
    background-color: appProp.$woodAltColor36;
  }
}

@keyframes rotateEffect {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes popupEffect {

  0%,
  100% {
    transform: translateY(0);
  }

  55% {
    transform: translateY(-7.5em);
  }
}

@keyframes popupEffect2 {

  0% {
    transform: translateY(5em);
  }

  55% {
    transform: translateY(2em);
  }

  100% {
    transform: translateY(-0.5em);
  }
}

@keyframes followpath {
  0% {
    motion-offset: 0;
    offset-distance: 0;
    scale: (0.8);
  }

  45% {
    motion-offset: 45%;
    offset-distance: 45%;
    scale: (0.9);
  }

  55% {
    motion-offset: 55%;
    offset-distance: 55%;
    scale: (1);
  }

  95% {
    motion-offset: 95%;
    offset-distance: 95%;
    scale: (1);
  }

  100% {
    motion-offset: 100%;
    offset-distance: 100%;
    scale: (.8);
  }
}

@keyframes flip-x {

  0% {
    opacity: 0;
    transform: translatez(0);
    transform: rotateY(45deg);
  }

  50% {
    opacity: 1;
    transform: translatez(0);
    transform: rotateY(210deg);
  }

  100% {
    opacity: 1;
    transform: translatez(0);
    transform: rotateY(360deg);
  }
}

@keyframes flip-x-back {

  0% {
    opacity: 1;
    transform: translatez(0);
    transform: rotateY(45deg);
  }

  50% {
    opacity: 1;
    transform: translatez(0);
    transform: rotateY(210deg);
  }

  100% {
    opacity: 1;
    transform: translatez(0);
    transform: rotateY(360deg);
  }
}

@keyframes flip-y-backward {

  0% {
    opacity: 0;
    transform: rotateX(90deg);
  }

  5% {
    opacity: 0.3;
    transform: rotateX(180deg);
  }

  50% {
    opacity: 1;
  }

  99% {
    opacity: 1;
    transform: rotateX(359deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes flip-y-forward {

  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  5% {
    opacity: 0.3;
    transform: rotateX(-180deg);
  }

  50% {
    opacity: 1;
  }

  99% {
    opacity: 1;
    transform: rotateX(-359deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes unflip-x {

  0% {
    transform: scaleX(-1);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes unflip-y {

  0% {
    transform: scaleY(-1);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes hide-on-flip {

  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes show-on-flip {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-70 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .7;
  }
}

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }

  3% {
    transform: scale3d(1.25, 0.75, 1);
  }

  4% {
    transform: scale3d(0.75, 1.25, 1);
  }

  5% {
    transform: scale3d(1.15, 0.85, 1);
  }

  6% {
    transform: scale3d(0.95, 1.05, 1);
  }

  7% {
    transform: scale3d(1.05, 0.95, 1);
  }

  10% {
    transform: scale3d(1, 1, 1);
  }
}

//#endregion KEYFRAMES