@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

$disabledColor: rgb(109 67 26 / 20%);
$activeColor: rgb(109 67 26 / 40%);

.Glass {
  border: appProp.$thinWoodenBorder;
  border-color: transparent;
  border-radius: 0.25em;
  box-shadow: 0 4px 5px 1px appProp.$black95;
}

.LinkWrapper {
  max-height: 3.6875em;
}

.RoundedButton {
  @include mixin.getFlexDisplay(row, nowrap, center, center);
  @include mixin.preventImageSelection();
  gap: 0.25em;
  width: max-content;
  border-radius: 0.25em;
  padding: 0.5em 0.75em;
  box-shadow: 0 0.0625em 0.25em 0.15em appProp.$black35;
  cursor: pointer;
  position: relative;
  transform: all .5s ease-out;

  &MaxHeight {
    max-height: 3.6875em;
  }

  &HighlightBg {
    background-color: appProp.$orange90;
  }

  &DangerBg {
    background-color: appProp.$danger70;
  }

  >h1 {
    @include mixin.getFontVariationSettingsImportant(0, 600);
    white-space: nowrap;
  }

  &:not(.Disabled):active {
    background-color: $activeColor;
  }

  &HighlightBg:not(.Disabled):active {
    background-color: appProp.$orange100;
  }

  &DangerBg:not(.Disabled):active {
    background-color: appProp.$danger100;
  }

  &.Disabled {
    background-color: $disabledColor;
    cursor: auto;

    >h1 {
      @include mixin.getInsetColorAndFontSettings(appProp.$woodColor50, 1, 500);
    }
  }

  &Loading {
    @include mixin.getFlexDisplay(row, unset, center, center);
    position: absolute;
    inset: 0;
    background-color: $disabledColor;

    &Icon[class*="material-symbols-outlined"] {
      transform-origin: center;
      @include appAnimation.rotateAnimation();
      @include mixin.getFontVariationSettings(0, 700);
      font-size: 2.5em;
    }

    // hide icon and text when visible
    &~h1 {
      visibility: hidden;
    }
  }
}

@media (hover) {
  .RoundedButton {
    &:not(.Disabled):hover {

      background-color: $activeColor;
      box-shadow: 0 0 0.25em 0.10em appProp.$black35;

      &.RoundedButtonBorderless {
        border: none;
      }

      >h1>span[class*="material-symbols-outlined"] {
        @include mixin.getFontVariationSettings(1, 600);
      }
    }

    &HighlightBg:not(.Disabled):hover {
      background-color: appProp.$orange100;
    }

    &DangerBg:not(.Disabled):hover {
      background-color: appProp.$danger100;
    }
  }
}