@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.InputText {
  font-family: 'Big Shoulders Display', 'BigShouldersDisplay', sans-serif !important;
  border-radius: 0.25em;
  border: 0.0625em solid transparent !important;

  &[class*='indent'] {
    background-color: appProp.$black05;
    box-shadow: appProp.$cellShadow;
  }

  //#region LABEL ***********************************************************************
  > [class*='MuiInputBase-root MuiOutlinedInput-root'],
  > [class*='MuiFormLabel-root MuiInputLabel-root'] {
    font-family: 'Big Shoulders Display', 'BigShouldersDisplay', sans-serif !important;
    font-size: 1.5em;
    line-height: 1.5em;
    border-radius: 0.25em;
    color: appProp.$primaryColor;
    @include mixin.getFontVariationSettings(0, 700);

    &[class*='small'] {
      font-size: 0.9em;
      padding-right: 0.25em;
      @include mixin.getFontVariationSettings(0, 500);

      > input {
        padding: 0 0.5em;
      }
    }

    &[class*='Mui-focused'] {
      color: appProp.$primaryColor;
    }
  }

  > [class*='MuiFormLabel-root MuiInputLabel-root'] {
    @include mixin.getFontVariationSettings(0, 600);
    line-height: 1.5em;
    color: appProp.$inputTextLabelColor;
  }

  > [class*='MuiFormLabel-root MuiInputLabel-root'] {
    &[class*='MuiInputLabel-shrink'],
    &[class*='MuiFormLabel-filled'],
    &[class*='Mui-focused'] {
      transform: translate(0.7em, -0.65em) scale(0.75);
    }
  }

  &[class*='indent'] {
    > [class*='MuiFormLabel-root MuiInputLabel-root'] {
      &[class*='MuiInputLabel-shrink'],
      &[class*='MuiFormLabel-filled'],
      &[class*='Mui-focused'] {
        transform: translate(1em, -1em) scale(0.7);
      }
    }
  }

  //#endregion

  //#region ICONS ***********************************************************************
  > [class*='MuiInputBase-root MuiOutlinedInput-root'] {
    font-family: 'Big Shoulders Display', cursive !important;
    font-size: 1.5em;
    line-height: 1.5em;

    input {
      font-family: 'Big Shoulders Display', cursive !important;

      &:-webkit-autofill,
      &:-webkit-autofill::first-line,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:active,
      &:-webkit-autofill:focus {
        font-family: 'Big Shoulders Display', cursive !important;
        transition: background-color 600000s 0s, color 600000s 0s;
      }
    }

    > [class*='MuiInputAdornment-root'] {
      font-family: 'Big Shoulders Display', cursive !important;
      @include mixin.preventImageSelection();
      font-size: 0.75em;
      line-height: 1em;
      width: 2em;
      height: 2em;
      padding: 0.25em;
      background: appProp.$white15;
      border-radius: 0.125em;
      box-shadow: 0 0.0625em 0.3125em 0.125em rgba(0, 0, 0, 0.4);
    }

    &[class*='small'] {
      & > [class*='MuiInputAdornment-root'] {
        margin: 0.5em 0;
        border-radius: 0.25em;
        font-size: 0.65em;
        aspect-ratio: 1;
        width: auto;
        height: auto;
        >span[class*="material-symbols-outlined"] {
          @include mixin.getFontVariationSettings(0, 600, 200, 36);
        }
      }
    }
  }

  //#region OUTLINE BORDER COLOR ********************************************************
  &[class*='indent'] {
    > [class*='MuiInputBase-root MuiOutlinedInput-root'] {
      fieldset[class*='MuiOutlinedInput-notchedOutline'] {
        border-color: transparent;
        border-width: 0.0001em;

        > legend {
          font-size: 0.7em;
        }
      }

      &:hover {
        fieldset[class*='MuiOutlinedInput-notchedOutline'] {
          border-color: appProp.$inputTextBorderHoverColorHint !important;
          border-width: 0.0001em;
        }
      }

      &[class*='Mui-focused'] {
        fieldset[class*='MuiOutlinedInput-notchedOutline'] {
          border-color: appProp.$inputTextBorderHoverColorHint !important;
          border-width: 0.0001em;
        }
      }
    }
  }

  &[class*='outline'] {
    > [class*='MuiInputBase-root MuiOutlinedInput-root'] {
      padding-right: 0.25em;

      fieldset[class*='MuiOutlinedInput-notchedOutline'] {
        border-color: appProp.$inputTextOutlineBorderColor;
        border-width: 2px !important;

        > legend {
          font-size: 0.7em;
        }
      }

      &:hover {
        fieldset[class*='MuiOutlinedInput-notchedOutline'] {
          border-color: appProp.$primaryHoverColor !important;
        }
      }

      &[class*='Mui-focused'] {
        fieldset[class*='MuiOutlinedInput-notchedOutline'] {
          border-color: appProp.$primaryHoverColor !important;
        }
      }
    }
  }

  //#endregion
}
