@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.XCloseButton {
  cursor: pointer;
  @include mixin.preventImageSelection();
  @include mixin.getNewFontStyle(FontStyle);
  color: transparent;

  &InsetText {
    line-height: 0.7em;
    text-shadow: 2px 2px 3px appProp.$white25;
    background-color: appProp.$woodColor100;
    background-clip: text;

    .XCloseButton {
      color: transparent;
      background-color: inherit;
      background-clip: text;
      @include mixin.getFontVariationSettings(0, 700, 200, 20);
    }

    @media (hover) {
      .XCloseButton:hover {
        text-shadow: 1px 1px 2px rgb(87 62 42 / 20%);
      }
    }

    .XCloseButton:active {
      text-shadow: 1px 1px 2px rgb(87 62 42 / 40%);
    }
  }
}
