@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/mixins.module.scss' as mixin;
@import '/src/scss/reset.scss';
@import '/src/scss/theme.module.scss';
@import 'material-symbols/outlined.scss';
@import '/src/fonts/fonts.css';

* {
  box-sizing: border-box;
}

//#region   SCROLLBAR *******************************************************************
::-webkit-scrollbar {
  width: 0.35em;
  height: 0.35em;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px 1px rgb(96, 95, 95);
  border-radius: 0.25em;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #59422c;
  border-radius: 0.25em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5f2601;
}

$myContainer: var(--game-container-height);

//#endregion

:focus {
  outline: 0;
  border: none;
  color: rgba(0, 0, 0, 0);
}

html {
  max-width: 100vw;
  background-color: appProp.$backgroundColor;
  height: 100dvh;
  overflow: auto;
  overflow-x: hidden;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: 'Big Shoulders Display', 'BigShouldersDisplay', sans-serif;
  @include mixin.getFontVariationSettings(0, 500);
  line-height: 1;
  color: appProp.$primaryColor;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  min-height: calc(var(--vh, 1lvh) * 100);
  min-width: calc(var(--vw, 1vw) * 100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100dvh;
  // overflow: visible;
}

// body,
#root {
  position: absolute;
  display: block;
  -webkit-font-smoothing: antialiased;
}

// hack from https: //css-tricks.com/the-fixed-background-attachment-hack/
#bg {
  background: url('/images/wooden-bg.webp') no-repeat center center;
  background-size: cover;
  position: fixed;
  height: 105vh;
  width: 105vw;
}

#root > div {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  width: 100dvw;
}

//#region CODE **************************************************************************
code {
  font-family: monospace;
}

pre {
  @include mixin.getInsetPanel();
  background-color: appProp.$selectedFilterItem;
  width: auto;
  align-self: center;
  font-family: monospace;
}

pre code {
  display: block;
  padding: 1em;
}

//#endregion

/*****************************
Laptops, etc
*****************************/
@media only screen and (max-width: 1024px) {
  html,
  body {
    font-size: 16px;
  }
}

/*****************************
Tablets
*****************************/
@media only screen and (max-width: 768px) {
  html,
  body {
    font-size: 14px;
  }
}

/*****************************
Pixel 6a
*****************************/
@media only screen and (max-width: 428px) {
  html,
  body {
    font-size: 12px;
  }
}

/*****************************
Older Pixel 2, or 3
*****************************/
@media only screen and (max-width: 375px) {
  html,
  body {
    font-size: 10px;
  }
}

/*****************************
Smallest resolution
*****************************/
@media only screen and (max-width: 320px) {
  html,
  body {
    font-size: 9px;
  }
}

a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

img {
  vertical-align: top;
}

h1 {
  display: flex;
  align-items: inherit;
  font-size: 4rem;
  // line-height: 2em;
  @include mixin.getFontVariationSettings(0, 700);
}

h2 {
  display: flex;
  align-items: inherit;
  font-size: 3em;
  line-height: 2em;
  @include mixin.getFontVariationSettings(0, 700);
}

h3 {
  display: flex;
  align-items: inherit;
  font-size: 2em;
  line-height: 1.5em;
  @include mixin.getFontVariationSettings(0, 700);
}

h4 {
  display: flex;
  align-items: inherit;
  font-size: 1.7em;
  line-height: 1.25em;
  @include mixin.getFontVariationSettings(0, 600);
}

h5 {
  display: flex;
  align-items: inherit;
  font-size: 1.55em;
  line-height: 1.15em;
  @include mixin.getFontVariationSettings(0, 500);
}

h6 {
  display: flex;
  align-items: inherit;
  font-size: 1.15em;
  line-height: 1.15em;
  @include mixin.getFontVariationSettings(0, 500);
}
