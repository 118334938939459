@use '/src/scss/mixins.module.scss' as mixin;

div[id="SwitchProfilePopupId"],
.SwitchProfilesPopup {

  .PopupFooter {
    @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
    @include mixin.getFillAvailableWidth();
  }

  .ActionButtons {
    @include mixin.getFlexDisplay(row, nowrap, center, space-between);
    @include mixin.getFillAvailableWidth();
    margin-top: 1em;
  }
}