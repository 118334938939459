@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.DebugButton {
  position: absolute;
  left: 50%;
  z-index: appProp.$debugPanelZ;
  @include mixin.getFlexDisplay(column, wrap, center, center);
  @include mixin.getFillAvailableWidth();

  &Active {
    @include mixin.getFontVariationSettings(1);
  }
}

.Animation {
  &Open {
    animation: slide-in-blurred-top 0.4s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  &Close {
    animation: slide-out-blurred-top 0.4s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    z-index: 1;
    .WoodBg {
      max-width: 3em;
    }
  }
}

.WoodBg {
  @include mixin.getPopupWoodBg();
  position: relative;
  border-radius: 0 0 .5em .5em;
  top: 0;
}

.ButtonRow {
  @include mixin.getFlexDisplay(row, nowrap, flex-start, flex-start, 1);
  @include mixin.getFillAvailableWidth();
  gap: 0.5em;
  margin: 0.25em 0.5em;
  padding-bottom: 0.75em;
}

.OpenCloseButton {
  @include mixin.getFlexDisplay(column, nowrap, center, center);
  cursor: pointer;
}

@media (hover) {
  .OpenCloseButton:hover {
    background-color: appProp.$black10;
  }
}


@keyframes slide-in-blurred-top {
  0% {
    transform: translateY(-95px) translateX(-15%);
    opacity: 0.25;
  }

  100% {
    transform: translateY(-18px) translateX(-15%);
    opacity: 1;
  }
}

@keyframes slide-out-blurred-top {
  0% {
    transform: translateY(-10px) translateX(-15%);
    opacity: 1;
  }

  100% {
    transform: translateY(-5.625em) translateX(-15%);
    opacity: 0.25;

  }
}