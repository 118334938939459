@use '/src/scss/mixins.module.scss' as mixin;

//#region SIZES
.LemonIcon,
.AppleIcon,
.OrangeIcon,
.WatermelonIcon,
.TomatoIcon {
  @include mixin.preventImageSelection();
  aspect-ratio: 1 / 1;

  &[class*='xxs'] {
    width: 1em;
    height: 1em;
  }

  &[class*='xs11'] {
    width: 1.1em;
    height: 1.1em;
  }

  &[class*='xsm'] {
    width: 1.25em;
    height: 1.25em;
  }

  &[class*='sma'] {
    width: 1.5em;
    height: 1.5em;
  }

  &[class*='sam'] {
    width: 1.6em;
    height: 1.6em;
  }

  &[class*='med'] {
    width: 1.75em;
    height: 1.75em;
  }

  &[class*='mel'] {
    width: 2em;
    height: 2em;
  }

  &[class*='lge'] {
    width: 2.5em;
    height: 2.5em;
  }

  &[class*='xtl'] {
    width: 3em;
    height: 3em;
  }

  &[class*='xxl'] {
    width: 3.25em;
    height: 3.25em;
  }

  &[class*='xl4'] {
    width: 4em;
    height: 4em;
  }

  &[class*='xl5'] {
    width: 5em;
    height: 5em;
  }

  &[class*='xl7'] {
    width: 7em;
    height: 7em;
  }

  &[class*='xl8'] {
    width: 8em;
    height: 8em;
  }

  &[class*='xl9'] {
    width: 9em;
    height: 9em;
  }

  &[class*='xl10'] {
    width: 100%;
    height: 100%;

    aspect-ratio: 1;
  }
}

//#endregion

.GreenTomatoIcon {
  @extend .TomatoIcon;
  filter: drop-shadow(0 0 1px #453831) hue-rotate(90deg);
}

.RedTomatoIcon {
  @extend .TomatoIcon;
  filter: drop-shadow(0 0 1px #453831) hue-rotate(27deg);
}