@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.ErrorBoundary {
  @include mixin.getFlexDisplay(column, wrap, center, flex-start);
  @include mixin.getFillAvailableWidth();
  align-self: center;
  gap: 1em;
  margin: 2em 1em;

  &Countdown {
    font-weight: 600;
    font-size: x-large;
  }

  &ErrorMessage {
    @include mixin.getFlexDisplay(column, wrap, center, flex-start);
    @include mixin.getFillAvailableWidth();
  }

  &ErrorText {
    @include mixin.getFillAvailableWidth();
    display: block;
    text-align: center;
    font-size: 2em;
  }

  &ServerValue {
    font-size: 2em;
  }

  &GreenerPastures {
    color: rgb(165, 80, 1);
    font-size: larger;
  }

  &Heading {
    @include mixin.getRaisedPanel();
    @include mixin.getFillAvailableWidth();
    @include mixin.getFontVariationSettings(0, 800);

    text-align: center;
    padding: 0.4em;
    font-size: larger;

    &Main {
      @extend .ErrorBoundaryHeading;
      font-size: x-large;
    }
  }

  &SubHeading {
    @include mixin.getFlexDisplay(row, nowrap, center, flex-start);
    @include mixin.getFillAvailableWidth();
    gap: 0.75em;

    &Title {
      @include mixin.getRaisedPanel();
      align-self: flex-start;
      padding: 0.4em;
      min-width: 4em;
      @include mixin.getFillAvailableWidth();
      max-width: 7em;
      @include mixin.getFontVariationSettings(0, 700);
    }

    &Info {
      @include mixin.getRaisedPanel();
      align-self: flex-start;
      padding: 0.4em;
      @include mixin.getFillAvailableWidth();
      white-space: pre-wrap;
      overflow: auto;
    }
  }


  &Message {
    @include mixin.getFillAvailableWidth();
    white-space: pre-wrap;

    &Display {
      @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start);
      // gap: 0.5em;
      @include mixin.getInsetPanel();
      gap: 1em;
      padding: .75em;
    }

    font-size: 1em;
  }
}