@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Monkey {
  @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
  @include mixin.getFillAvailableWidth();

  &Head {
    filter: drop-shadow(1px 2px 2px #00000050);

    &[class*='lge'] {
      width: 14em;
      // height: 14em;
    }

    &[class*='med'] {
      width: 10em;
      // height: 10em;
    }

    &[class*='sma'] {
      width: 6em;
      // height: 6em;
    }

    &[class*='xsm'] {
      width: 4em;
      // height: 6em;
    }
  }
}
