@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Rule {
  @include mixin.getFillAvailableWidth();
  box-shadow: 0 0.0875em 0.35em 1px rgba(38, 21, 3, 0.6);
  border-end-end-radius: 0.25em;
  border-end-start-radius: 0.25em;
  border-start-start-radius: 0.25em;
  border-start-end-radius: 0.25em;

  // $thinWoodenBorder: 0.0625em groove $woodAltColor50; // 1 px
  // $medWoodenBorder: 0.1250em groove $woodAltColor50; // 2 px
  // $thickWoodenBorder: 0.2500em groove $woodAltColor50; // 4 px
  // $extraThickWoodenBorder: 0.375em groove $woodAltColor50; // 6 px


  &[class*="zeroMargin"] {
    margin: 0;
  }

  &[class*="ultra"] {
    border: 0.0025em solid rgb(204 171 141 / 30%);
  }

  &[class*="thin"] {
    border: 0.0925em solid rgb(204 171 141 / 30%);
  }

  &[class*="med"] {
    border: 0.15em solid rgb(204 171 141 / 30%);
  }

  &[class*="thick"] {
    border: 0.25em solid rgb(204 171 141 / 30%);
  }

  &[class*="extra"] {
    border: 0.375em solid rgb(204 171 141 / 30%);
  }
}