$white15: rgb(255 255 255 / 15%);
$white20: rgb(255 255 255 / 20%);
$white25: rgb(255 255 255 / 25%);

$grey100: rgb(97 97 97 / 100%);

// #region BLACKBOXSHADOW COLOR ALPHAS
$black100: rgb(0 0 0 / 100%);
$black95: rgb(0 0 0 / 95%);
$black50: rgb(0 0 0 / 50%);
$black45: rgb(0 0 0 / 45%);
$black40: rgb(0 0 0 / 40%);
$black35: rgb(0 0 0 / 35%);
$black25: rgb(0 0 0 / 25%);
$black20: rgb(0 0 0 / 20%);
$black15: rgb(0 0 0 / 15%);
$black10: rgb(0 0 0 / 10%);
$black05: rgb(0 0 0 / 5%);
$black02: rgb(0 0 0 / 2%);
$black015: rgb(0 0 0 / 1.5%);

$blackBoxShadow50: 0 0.0625em 0.1875em 0.0625em $black50;
$blackBoxShadow35: 0 1px 5px 2px $black35;
$blackBoxShadow25: 0 1px 5px 2px $black25;
$blackBoxShadow05: 0 1px 5px 2px $black05;
//#endregion

// #region WOOD COLORS
$woodColor100: rgb(85 42 0 / 100%);
$woodColor90: rgb(85 42 0 / 90%);
$woodColor80: rgb(85 42 0 / 80%);
$woodColor70: rgb(85 42 0 / 70%);
$woodColor60: rgb(85 42 0 / 60%);
$woodColor50: rgb(85 42 0 / 50%);
$woodColor40: rgb(85 42 0 / 40%);
$woodColor30: rgb(85 42 0 / 30%);
$woodColor20: rgb(85 42 0 / 20%);
$woodColor15: rgb(85 42 0 / 15%);
$woodColor10: rgb(85 42 0 / 10%);

$woodDarkColor: rgb(74 33 7 / 100%);
$woodLightColor: rgb(145 73 1 / 50%);

$woodAltColor100: rgb(109 67 26 / 100%);
$woodAltColor85: rgb(109 67 26 / 95%);
$woodAltColor85: rgb(109 67 26 / 85%);
$woodAltColor80: rgb(109 67 26 / 80%);
$woodAltColor50: rgb(109 67 26 / 50%);
$woodAltColor36: rgb(109 67 26 / 36.3%);
$woodAltColor15: rgb(109 67 26 / 15%);
$woodAltColor10: rgb(109 67 26 / 10%);

$woodGreenColor100: rgb(66 75 63 / 100%);
$woodGreenColor95: rgb(66 75 63 / 95%);

$woodSandColor100: rgb(222 184 135 / 100%);
$woodSandColor35: rgb(222 184 135 / 35%);
//#endregion

// #region BORDER COLORS
$thinWoodenBorder: 0.0625em groove $woodAltColor50; // 1 px
$medWoodenBorder: 0.125em groove $woodAltColor50; // 2 px
$thickWoodenBorder: 0.25em groove $woodAltColor50; // 4 px
$extraThickWoodenBorder: 0.375em groove $woodAltColor50; // 6 px
// #endregion

// #region MAIN APP COLORS

$primaryColor: rgb(85 42 0);
$primaryColor10: rgb(85 42 0 / 10%);
$primaryColor25: rgb(85 42 0 / 25%);
$primaryColor30: rgb(85 42 0 / 30%);
$primaryColor70: rgb(85 42 0 / 70%);
$primaryColor80: rgb(85 42 0 / 80%);
$primaryHoverColor: rgb(105 53 1);
$primaryFocusColor: rgb(131 67 2);
$backgroundColor: rgb(147 111 78);
$secondaryColor: rgb(146 88 31);
$selectedFilterItem: rgb(255 158 64 / 100%);
$unselectedIconColor80: rgb(195 134 75 / 80%);
$unselectedIconColor100: rgb(195 134 75 / 100%);
$unselectedIconColor: rgb(195 134 75 / 100%);
// scene defaults
$sceneMaxWidth: 50em;
// #endregion

// #region MAIN APP COLORS
$primaryFilterBrightness: brightness(1.2);
$sparkMaxBrightness: brightness(1.9);
// #endregion

// #region INPUT TEXT COLORS
$inputTextOutlineBorderColor: rgb(127 63 0);
$inputTextLabelColor: rgb(146, 88, 31);
$inputTextBorderHoverColorHint: rgb(0 0 0 / 25%);
// #endregion

// #region ORANGE COLORS
$orangeEnabled: rgb(255 125 0);
$orange100: rgb(255 158 64 / 100%);
$orange90: rgb(255 158 64 / 90%);
$orange70: rgb(255 209 165 / 70%);
$orange25: rgb(255 158 64 / 25%);
// #endregion

// #region DANGER COLORS
$danger100: rgb(240 0 0 / 100%);
$danger90: rgb(240 0 0 / 90%);
$danger80: rgb(240 0 0 / 80%);
$danger70: rgb(240 0 0 / 70%);
// #endregion

// #region COLLAPSIBLE SUB-LISTS BG COLORS
$childGroupBackgroundColor: rgb(176 123 70 / 25%);
$grandChildGroupBackgroundColor: rgb(255 235 205 / 30%);
// #endregion

// #region RESULT ANIMATION COLORS pass and fail
$resultPassColor: rgb(41 224 9 / 62%);
$resultPassColorAlt: rgb(21 143 0);
$resultPassColorAlt70: rgb(21 143 0 / 70%);
$resultFailColor: rgb(255 0 0 / 15%);
$resultFailColorAlt: rgb(207 0 0);
// animating loader
$animatingSize: 144px;
// #endregion

// #region STRIPE COLORS IN FULL MODE OF CAROUSEL HEADER
$seagreenColor: rgb(174 208 158 / 50%);
$forestgreenColor: rgb(89 237 59 / 50%);
$lightgoldColor: rgb(255 251 183 / 50%);
$lightwoodColor: rgb(240 178 85 / 50%);
$lightwoodColor100: rgb(240 178 85 / 100%);
$lemonColor50: rgb(255 218 77 / 50%);
//#endregion

// #region Z-INDEXES
$boardZ: 2;
$countModuleShield: 3;
$textOnPanelZ: 5;
$gameInfoPanel: 15;
$tutorialTabsToast: 16;
$cardHelpPopupZ: 20;
$genericPopupZ: 21;
$listAsPopupZ: 50;
$fabZ: 55;
$selectPopupZ: 75;
$appMenuZ: 100;
$completionNotificationZ: 110;
$blockingPopupZ: 111;
$toastZ: 120;
$debugPanelZ: 150;
$helpPanelSpotZ: 130;
$helpPanelZ: 131;
//#endregion

// #region TOAST NOTIFICATION MESSAGE COLORS & SIZES
$successColor: rgb(22, 134, 0);
// $successBtnColor: rgb(60 227 27 / 58%);
$successBtnColor: rgb(27 162 0 / 30%);
// $dangerColor: rgb(255 127 80 / 62%);
$dangerColor: rgb(255 68 0 / 30%);
$warningColor: rgb(255, 140, 25);
$errorColor: rgb(179 44 12);
$infoColor: rgb(0, 149, 199);
// SIZES
$toastMaxWidth: 25em;
$toastMinWidth: 15em;
$toastMaxHeight: 7em;
$toastMinHeight: 3.5em;
//#endregion

// #region INDENT EFFECT WITH DROP-SHADOW
$cellLightShadowColor: rgb(233 233 233 / 20%);
$cellDarkShadowColor60: rgb(38 21 3 / 60%);
$cellDarkShadowColor30: rgb(38 21 3 / 30%);
$cellDarkShadowColor15: rgb(38 21 3 / 15%);
$cellDarkShadowColorAlt: rgb(38 21 3 / 40%);
$headerCellDarkColor: rgb(38 21 3 / 25%);
$cellBackground: rgb(0 0 0 / 2.5%);
$cellBackgroundHover: rgb(0 0 0 / 4.5%);
$cardActiveBackground: rgb(255 158 64 / 90%);
$insetShadow: 0 0.125em 0.3125em 0.0625em $cellLightShadowColor, inset 0 0.0875em 0.45em 0 $cellDarkShadowColor60;
$cellShadow: 0 0.125em 0.3125em 0.0625em $cellLightShadowColor, inset 0 0.1875em 0.25em 0 $cellDarkShadowColor60,
  inset 0 -0.1em 0.1em 0 $cellDarkShadowColor15;
$collapsibleListShadow: 0 0.0625em 0.3125em 0.0625em $cellLightShadowColor,
  inset 0 0.1875em 0.25em 0 $cellDarkShadowColor60, inset 0 -0.1em 0.15em 0em $cellDarkShadowColor30;
$cellShadowAlt: 0 0.125em 0.3125em 0.0625em $cellLightShadowColor, inset 0 0.15em 0.15em 0 $cellDarkShadowColorAlt;
$headerCellShadow: 0.125em 0.3125em 0.0625em $cellLightShadowColor, inset 0 -0.0625em 0.25em 0 $cellDarkShadowColor60,
  0 0.0625em 0.0625em 0 $headerCellDarkColor;
$insetStyle: 0 0.125em 0.3125em 0.0625em $cellLightShadowColor, inset 0 0.0875em 0.45em 0 $cellDarkShadowColor60;
//#endregion

// #region CREST SIZES
$size3600: 36em;
$size3500: 35em;
$size2500: 25em;
$size1875: 18.75em;
$size1600: 16em;
$size800: 8em;
$size700: 7em;
$size660: 6.6em;
$size300: 3em;
$size165: 1.65em;
//#endregion

// #region BASIC CARD / SIMPLE CARD SIZES
$cardFullHeight: 10.3125em;
$cardMiniHeight: 3em;
$cardWrapperPadding: 0.15em;
$cardBodyPadding: 0.3em;
$cardBodyMiniHeight: 2.7em;
$cardMaxWidth: 32em;
//#endregion

// #region STAR PATH
$spinning-star-path: path(
  'm129.675 11.5125c-1.539-.8438-3.483-1.6687-7.263-2.3438-.486-.0938-1.026-.1687-1.566-.2438-3.672-.525-8.451-.9187-14.013-1.1437-12.825-.5063-29.133-.1688-44.685.825-15.498.9938-30.24 2.6438-40.5 4.5938-5.076.975-9.045 2.025-11.07 3.0563-1.08.525-1.674 1.0313-1.782 1.5188-.135.2437.027.4875.081.7125.243.225.378.4688.81.675 1.404.8813 4.536 1.6313 8.505 2.2688 3.942.6375 8.856 1.1812 15.147 1.4625 1.89.0938 3.888.15 5.94.1875 4.833.0938 10.044.0938 15.417-.0375 7.695-.1687 15.633-.5625 23.247-1.0875 7.668-.525 15.012-1.2188 21.762-1.9875 6.75-.7875 12.933-1.6687 17.901-2.625 4.995-.9563 8.937-1.9688 10.962-2.9812 2.052-1.0125 2.457-1.9875 1.053-2.85z'
);
$sun-curved-path: path('m 0 0 a 23 10 0 0 1 400 0 a 40 5 0 0 1 0 0 z');
// #endregion
