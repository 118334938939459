@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Header {
  @include mixin.getFlexDisplay(column, wrap, center, center);
  @include mixin.getFillAvailableWidth();
  margin: 0 0.5em 0.5em;
}

.CloseButton {
  position: absolute;
  top: 0;
  right: 0;
}

.SettingsButton {
  position: absolute;
  bottom: 0.3em;
  left: 0.3em;
}

@media (hover) {
  .SettingsButton:hover > [class*='material-symbols-outlined'][class*='MeIcon'] {
    text-shadow: 0 1px 4px rgb(87 62 42);
  }
}
.SettingsButton:active > [class*='material-symbols-outlined'][class*='MeIcon'] {
  text-shadow: 0 1px 5px rgb(87 62 42);
}
