@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss';

.CreateMessageForm {
  @include mixin.getFlexDisplay(column, wrap, center, flex-start, 1);
  @include mixin.getFillAvailableWidth();
  margin-top: -2em;
  min-width: 25em;
  max-width: 30em;
  min-height: 30em;
  gap: 1.5em;
}