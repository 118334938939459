@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.TitlePanel {
  &::after {
    content: ' ';
    @include mixin.getFlexDisplay(column, unset, center, center, 1);
    position: absolute;
    border-radius: 0.225em;
    box-shadow: appProp.$blackBoxShadow50;
    height: 2.8em;
    right: 0.5em;
    left: 0.5em;
    bottom: 0em;
    backdrop-filter: blur(10px) brightness(1.5);
  }

  margin: 1.65em 0 0.7em;
  text-transform: uppercase;
  white-space: nowrap;
  position: absolute;
  right: 0.5em;
  left: 0.5em;
  bottom: 0.5em;

  >div>a {
    z-index: appProp.$textOnPanelZ;
    display: block;
    text-align: center;
  }
}