@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

$linkColor: rgba(255 146 37 / 21%);

.MeArticle {
  @include mixin.getFlexDisplay(column);
  @include mixin.getFillAvailableWidth();
  gap: 0.25em;

  ul {
    margin: 0 0 0 1.75em;
    @include mixin.getFontVariationSettingsImportant(0, 700);

    >li {
      width: 100%;
      list-style-type: circle;
      font-size: 1.5em;
      margin-bottom: 0.5em;
      padding-left: 0.25em;
    }
  }

  &Paragraph {
    @include mixin.getFontVariationSettings(0, 700);
    margin: 0 0 0 1.2em;
    position: relative;
    padding-left: 1em;
    margin-bottom: 1em;

    // Displays a vertical line left of the paragraph along the height of `<p>` tag
    &::after {
      content: '';
      border-left: 0.2em solid appProp.$woodAltColor80;
      position: absolute;
      left: -0.4em;
      top: 0;
      height: 100%;
    }

    a {
      @include mixin.getFontVariationSettings(0, 700);
      text-decoration: underline;
      background-color: $linkColor;
    }
  }

  &Column {
    @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    gap: 1em;
  }

  &Row {
    @include mixin.getFlexDisplay(row, wrap, center, space-between, 1);
    @include mixin.getFillAvailableWidth();
    gap: 1em;
  }
}