@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.AwardsDisplay {
  @include mixin.getFlexDisplay(row, nowrap, center, flex-end, 1);
  width: max-content;
  gap: 0.15em;

  &AnimateStar {
    animation: 0.9s jello-vertical 1s both;
  }
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(0.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, 0.75, 1);
  }

  50% {
    transform: scale3d(0.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, 0.95, 1);
  }

  75% {
    transform: scale3d(0.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}