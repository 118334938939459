@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

$fontVarUnfilled: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48;
$fontVarFilled: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;

%notificationFrame {
  content: ' ';
  @include mixin.getFlexDisplay(column, unset, center, center, 1);
  position: absolute;
  border-radius: 0.25em;
}

.CompleteNotification {
  position: fixed;
  min-width: 18em;
  max-width: 30em;
  max-height: 30em;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  @include mixin.preventImageSelection();
  @include mixin.getFlexDisplay(column, nowrap, center, flex-start, 1);
  // @include mixin.getFillAvailableWidth();
  z-index: -1;

  &Contents {
    position: relative;
    @include mixin.getFlexDisplay(column, nowrap, center, flex-start);
    @include mixin.getFillAvailableWidth();
    @include mixin.preventImageSelection();
    @include mixin.getPopupWoodBg();
    @include mixin.getTransformStyle(flat);
    border-radius: .5em;
    gap: .5em;
    font-variation-settings: $fontVarUnfilled;
    height: 100%;

    &::before {
      @extend %notificationFrame;
      inset: 0.35em;
      box-shadow: appProp.$blackBoxShadow50;
    }

    &::after {
      content: ' ';
      inset: 0.55em;
      box-shadow: appProp.$cellShadow;
    }

    .ActionButton {
      margin-bottom: 1em;
    }
  }

  &Icon[class*="material-symbols-outlined"] {
    font-size: 12em;
    filter: drop-shadow(1px 2px 4px rgb(0 0 0 / 80%));
    color: appProp.$orange100;
    @include mixin.getFontVariationSettings(1, 300, 0);
  }

  &Title {
    &::before {
      content: ' ';
      @include mixin.getFlexDisplay(column, unset, center, center, 1);
      position: absolute;
      inset: 1em;
      border-radius: 0.225em;
      box-shadow: appProp.$blackBoxShadow50;
      height: 3.8em;
    }

    margin: 1.65em 0 0.7em;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &:not([class*="PLAY"]) {
    opacity: 0;
  }

  &[class*="PLAY"],
  &[class*="OFF"] {
    z-index: appProp.$completionNotificationZ;
    @include mixin.getTransformStyle(flat);
    opacity: 1;
  }

  &[class*="PLAY"] {
    .CompleteNotificationContents {
      cursor: pointer;

      &.SlideElliptic {
        animation: slide-in-elliptic-bottom-fwd 1s ease-in both;
      }
    }
  }

  &[class*="OFF"] {
    .CompleteNotificationContents {

      &.SlideElliptic {
        animation: slide-out-elliptic-top-bck 0.7s ease-in-out both;
      }
    }
  }
}

// #region KEYFRAMES ********************************************************************
@keyframes slide-in-elliptic-bottom-fwd {
  0% {
    transform: translate3d(0, 600px, 0) rotate3d(1, 0, 0, 30deg) scale3d(0, 0, 0);
    -webkit-transform: translate3d(0, 600px, 0) rotate3d(1, 0, 0, 30deg) scale3d(0, 0, 0);
    transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    opacity: 0;
  }

  75%,
  100% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0) scale3d(1, 1, 1);
    -webkit-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0) scale3d(1, 1, 1);
    transform-origin: 50% -1400px;
    -webkit-transform-origin: 50% -1400px;
    opacity: 1;
  }
}

@keyframes slide-out-elliptic-top-bck {
  0% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0) scale3d(1, 1, 1);
    -webkit-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0) scale3d(1, 1, 1);
    transform-origin: 50% 1400px;
    -webkit-transform-origin: 50% 1400px;
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -600px, 0) rotate3d(1, 0, 0, 30deg) scale3d(0, 0, 0);
    -webkit-transform: translate3d(0, -600px, 0) rotate3d(1, 0, 0, 30deg) scale3d(0, 0, 0);
    transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    opacity: 0;
  }
}

@keyframes blur-on-enter {
  0% {
    filter: blur(3px);
  }

  100% {
    filter: blur(0);
  }
}

// #endregion