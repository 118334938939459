@use '/src/scss/mixins.module.scss' as mixin;

.Display {
  @include mixin.getFlexDisplay(column, wrap, center, center, 1);
  @include mixin.getFillAvailableWidth();
  text-align: center;
  gap: 1em;
}

.MonkeyThinking {
  position: absolute;
  z-index: 1;
  top: 0;
  scale: 0.6;
  transform: translate(-75%, -80%);
}
