@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

.MeIcon {
  color: appProp.$woodColor90;

  &Unselected[class*='material-symbols-outlined'] {
    color: appProp.$unselectedIconColor;
  }

  // #region FONT SIZE and WEIGHT *******************************************************
  &[class*='material-symbols-outlined'] {
    // #region FONT SIZES
    &[class*='xs5'] {
      font-size: 0.5rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xs6'] {
      font-size: 0.6rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xs75'] {
      font-size: 0.75rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xs9'] {
      font-size: 0.9rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xs1'] {
      font-size: 1rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xs11'] {
      font-size: 1.1rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xs12'] {
      font-size: 1.2rem;

      @extend %UpperCaseWithShadow;
    }

    &[class*='xs145'] {
      font-size: 1.45rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xs15'] {
      font-size: 1.5rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xss'] {
      font-size: 1.15rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='xsm'] {
      font-size: 0.9rem;
      @extend %UpperCaseWithShadow;
    }

    &[class*='sml'] {
      font-size: 1.25rem;
    }

    &[class*='sma'] {
      font-size: 1.5rem;
    }

    &[class*='sam'] {
      font-size: 1.65rem;
    }

    &[class*='med'] {
      font-size: 1.75rem;
      line-height: 1.143em;
    }

    &[class*='mel'] {
      font-size: 2rem;
      line-height: 1.2em;
    }

    &[class*='mlg'] {
      font-size: 2.35rem;
      line-height: 1.2em;
    }

    &[class*='lge'] {
      font-size: 2.54rem;
      line-height: 1.5em;
    }

    &[class*='lg35'] {
      font-size: 3.5rem;
      line-height: 1em;
    }

    &[class*='xtl'] {
      font-size: 3rem;
      line-height: 1.5em;
    }

    &[class*='xxl'] {
      font-size: 4rem;
      line-height: 1.5em;
    }

    &[class*='xl3'] {
      font-size: 3rem;
      line-height: 1em;
    }

    &[class*='xl35'] {
      font-size: 3.5rem;
      line-height: 1em;
    }

    &[class*='xl4'] {
      font-size: 4rem;
      line-height: 1em;
    }

    &[class*='xl5'] {
      font-size: 5rem;
      line-height: 1.5em;
    }

    &[class*='xl6'] {
      font-size: 6rem;
      line-height: 1.5em;
    }

    &[class*='xl7'] {
      font-size: 7rem;
      line-height: 1.5em;
    }

    &[class*='xl8'] {
      font-size: 8rem;
      line-height: 1.5em;
    }

    &[class*='xl9'] {
      font-size: 9rem;
      line-height: 1.2em;
    }

    &[class*='xl10'] {
      font-size: 10rem;
    }

    &[class*='xl15'] {
      font-size: 15rem;
    }

    &[class*='xl20'] {
      font-size: 20rem;
    }
    // #endregion

    // #region FONT LINE HEIGHT
    &[class*='line1'] {
      line-height: 1em;
    }

    &[class*='line11'] {
      line-height: 1.1em;
    }

    &[class*='line12'] {
      line-height: 1.2em;
    }

    &[class*='line13'] {
      line-height: 1.3em;
    }

    &[class*='line14'] {
      line-height: 1.4em;
    }

    &[class*='line15'] {
      line-height: 1.5em;
    }

    &[class*='line16'] {
      line-height: 1.6em;
    }

    &[class*='line17'] {
      line-height: 1.7em;
    }

    &[class*='line18'] {
      line-height: 1.8em;
    }

    &[class*='line19'] {
      line-height: 1.9em;
    }

    &[class*='line2'] {
      line-height: 2em;
    }
    // #endregion

    // #region FONT WEIGHT
    &[class*='bold900'] {
      @include mixin.getFontVariationSettings(0, 900);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 900);
      }
    }

    &[class*='bold800'] {
      @include mixin.getFontVariationSettings(0, 800);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 800);
      }
    }

    &[class*='bold700'] {
      @include mixin.getFontVariationSettings(0, 700);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 700);
      }
    }

    &[class*='bold600'] {
      @include mixin.getFontVariationSettings(0, 600);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 600);
      }
    }

    &[class*='bold500'] {
      @include mixin.getFontVariationSettings(0, 500);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 500);
      }
    }

    &[class*='bold400'] {
      @include mixin.getFontVariationSettings(0, 400);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 400);
      }
    }

    &[class*='bold300'] {
      @include mixin.getFontVariationSettings(0, 300);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 300);
      }
    }

    &[class*='bold200'] {
      @include mixin.getFontVariationSettings(0, 200);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 200);
      }
    }

    &[class*='bold100'] {
      @include mixin.getFontVariationSettings(0, 100);

      &.MeIconFilled {
        @include mixin.getFontVariationSettings(1, 100);
      }
    }
    // #endregion
  }

  // #endregion
}

.UsePointerCursor {
  cursor: pointer;
}

@media (hover) {
  .MeIcon[class*='material-symbols-outlined'] {
    &[class*='bold900'].UsePointerCursor:hover {
      @include mixin.getFontVariationSettings(1, 900);
    }
    &[class*='bold800'].UsePointerCursor:hover {
      @include mixin.getFontVariationSettings(1, 800);
    }
    &[class*='bold700'].UsePointerCursor:hover {
      @include mixin.getFontVariationSettings(1, 700);
    }
    &[class*='bold600'].UsePointerCursor:hover {
      @include mixin.getFontVariationSettings(1, 600);
    }
    &[class*='bold500'].UsePointerCursor:hover {
      @include mixin.getFontVariationSettings(1, 500);
    }
    &[class*='bold400'].UsePointerCursor:hover {
      @include mixin.getFontVariationSettings(1, 400);
    }
    &[class*='bold300'].UsePointerCursor:hover {
      @include mixin.getFontVariationSettings(1, 300);
    }
    &[class*='bold200'].UsePointerCursor:hover {
      @include mixin.getFontVariationSettings(1, 200);
    }
    &[class*='bold100'].UsePointerCursor :hover {
      @include mixin.getFontVariationSettings(1, 100);
    }
  }
}

.DisabledButton {
  cursor: not-allowed;
}

.InsetIcon {
  @include mixin.getInsetFontStyle();
  padding: 0.1em;
  gap: 0.2em;

  &.DangerBg {
    background-color: appProp.$dangerColor;
  }

  &.MainBg {
    background-color: appProp.$orange100;
  }

  &.SuccessBg {
    background-color: appProp.$successBtnColor;
  }

  &.InfoBg {
    background-color: appProp.$infoColor;
  }

  > span[class*='material-symbols-outlined'][class*='MeIcon'] {
    color: transparent;
  }
}
