@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.AdminNote {
  position: relative;
  @include mixin.getFlexDisplay(column, wrap, flex-start, center);
  @include mixin.getFillAvailableWidth();
  padding: 1.5em 0.5em 0.5em;
  overflow: hidden;
  z-index: 2;

  &Layout {
    @include mixin.getPopupWoodBg();
    @include mixin.getFlexDisplay(column, wrap, flex-start, center);
    border-radius: 0.5em;
    position: fixed;
    bottom: 0.5em;
    left: 5em;
    min-height: 3em;
    max-height: 15em;
    min-width: 10em;
    // max-width: 30em;
    z-index: 1;
    pointer-events: all;
  }

  &CloseButton {
    z-index: 3;
    position: fixed;
    top: 0;
    pointer-events: all;
    text-align: right;
    width: 100%;
    scale: 1;
    background-color: #ff7c0054;
  }
}

.Closed {
  &.AdminNoteLayout {
    border-radius: 50%;
    min-height: 2.3em;
    min-width: 2.3em;
    overflow: hidden;
  }
  > .AdminNote {
    display: none;
  }
  > .AdminNoteCloseButton {
    background-color: transparent;
    text-align: center;
    position: relative;
    width: 100%;
    scale: 2;
  }
}
