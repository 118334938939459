@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

%rowBase {
  @include mixin.getFillAvailableWidth();
  @include mixin.getFlexDisplay(row, unset, flex-start, center);
  color: appProp.$woodColor100;
  gap: 0.25em;
  cursor: inherit;
}

.List {
  @include mixin.getFlexDisplay(column, unset, center, flex-start);
  @include mixin.getFillAvailableWidth();

  align-self: center;
  background-color: #a385623b;
  max-height: 90%;
  padding: 0.25em;
  box-shadow: appProp.$cellShadow;
  border-radius: 0.3125em;
  will-change: transform;
  transform-origin: top;

  &[class*='show'] {
    transition: opacity 0.3s linear, transform 0.3s linear 0.3s;
    transform: scaleY(1);
    opacity: 1;
    gap: 0.25em;
    max-height: 4000em;
  }

  &[class*='hide'] {
    transition: transform 0.3s linear 0s;
    transform: scaleY(0);
    position: absolute;
  }

  &ActionCell {
    cursor: pointer;
    @include mixin.getFontVariationSettings(0, 500);

    &.Selected {
      background-color: appProp.$woodAltColor50;

      span {
        @include mixin.getFontVariationSettings(1, 500);
      }
    }

    &:active {
      >div[class*='ListCell'] {
        background-color: appProp.$woodAltColor85;
      }
    }
  }

  .RowDisplay {
    display: flex;
  }

  &Title {
    @include mixin.getFillAvailableWidth();
    @include mixin.getFlexDisplay(row, unset, center, space-between);
    color: appProp.$woodColor100;
  }

  &Row {
    @extend %rowBase;

    &[aria-disabled="true"] {
      cursor: default;
    }

    &:not([aria-disabled="true"]) {
      cursor: pointer;
    }

    &.HighlightRow {
      >div[class*='ListCell'] {
        background-color: appProp.$lightwoodColor;
      }
    }

    &:hover {

      // -webkit-tap-highlight-color: appProp.$black50;
      >div[class*='ListCell'] {
        @include appAnimation.hoverAnimation();
        border-radius: 0.25em;
      }
    }

    &:focus {
      >div[class*='ListCell'] {
        background-color: appProp.$woodAltColor36;
        -webkit-tap-highlight-color: appProp.$woodAltColor36;
      }
    }

    &:active {
      >div[class*='ListCell'] {
        background-color: appProp.$woodAltColor10;
      }
    }

    &Header {
      @extend %rowBase;

      .ListCell {
        user-select: none;
        background-color: appProp.$woodLightColor;
        color: appProp.$woodDarkColor;
        box-shadow: appProp.$headerCellShadow;

        >h2,
        >h3,
        >h4,
        >h5,
        >h6 {
          display: flex;
        }
      }
    }
  }

  &Cell {
    padding: 0.15em 0.3em 0;
    align-items: center;
    display: flex;
    flex: 1;
    height: 3.125em;
    min-width: 3.125em;
    user-select: none;
    cursor: inherit;
    box-shadow: appProp.$cellShadow;
    background-color: rgba(0, 0, 0, 0.015);
    border-radius: 0.3125em;

    &[class*='quarter'] {
      flex: 0.25;
    }

    &[class*='third'] {
      flex: 0.33;
    }

    &[class*='half'] {
      flex: 0.5;
    }

    &[class*='one'] {
      flex: 1;
    }

    &[class*='onalf'] {
      flex: 1.5;
    }

    &[class*='double'] {
      flex: 2;
    }

    &[class*='left'] {
      justify-content: flex-start;
    }

    &[class*='center'] {
      justify-content: center;
    }

    &[class*='right'] {
      justify-content: flex-end;
    }

    >h2,
    >h3,
    >h4,
    >h5,
    >h6 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    &.CopyText {
      position: relative;

      .CopyTextClipboardIcon {
        cursor: pointer;
        line-height: 1;
        position: absolute;
        cursor: pointer;
        opacity: 0.5;
        position: absolute;
        top: 0.2em;
        right: 0.1em;
      }
    }
  }

  &TwoLineCell {
    @include mixin.getFlexDisplay(column, wrap, flex-start, flex-start);
    display: inline-flex;
    overflow: hidden;
    text-overflow: ellipsis;

    &SingleLine {
      white-space: nowrap;
      // overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.ListFilter {
  @include mixin.getFlexDisplay(column, unset, flex-start, center);
  @include mixin.getFillAvailableWidth();
  gap: 0.25em;

  &Item {
    @include mixin.getFlexDisplay(row, nowrap, center, center, 1);
    gap: 0.25em;

    .MaxWidth {
      min-width: 0.75em;
      max-width: 0.75em;
    }

    [class*="ListCell"] {
      background-color: appProp.$woodAltColor15;
      height: 2.75em;

      >span[class*="material-symbols-outlined"] {
        font-size: 2em;
        color: transparent;
        text-shadow: 2px 2px 3px appProp.$white25;
        background-color: appProp.$primaryColor;
        @include mixin.getFontVariationSettings(0, 600);
        line-height: 1em;
        background-clip: text;
        position: relative;
      }

      >span:not([class*="Unselected"]) {
        @include mixin.getFontVariationSettings(1, 600);
      }

      >span.Unselected {
        @include mixin.getFontVariationSettings(0, 600);
      }
    }
  }
}

.ListSection {
  @include mixin.getFlexDisplay(column, nowrap, center, flex-start, 1);
  @include mixin.getInsetPanel();
  padding: 0.5em;
  gap: 0.5em;
  min-width: fit-content;

  &Header {
    @include mixin.getFlexDisplay(row, nowrap, center, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    gap: 0.5em;
    padding-bottom: 0.25em;

    >h1 {
      min-width: fit-content;
    }
  }
}