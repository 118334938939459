@use '/src/scss/mixins.module.scss' as mixin;

.MontessoriHome {
  @include mixin.getSceneBodyLayout();
  overflow: hidden;

  &Body {
    @include mixin.getFlexDisplay(row, wrap, flex-start, flex-start);
    @include mixin.getFillAvailableWidth();
    position: relative;
    gap: 1em;
  }
}