@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

// props to set specific style on List, CollapsibleList based on passed-in props
%customStyleProps {
  &[class*='transform-top'] {
    transform-origin: top;

    .CollapsibleListWrapper {
      transform-origin: top;
    }
  }

  &[class*='transform-bottom'] {
    transform-origin: bottom;

    .CollapsibleListWrapper {
      transform-origin: bottom;
    }
  }

  &[class*='pos-bottom'] {
    flex-direction: column-reverse;
  }

  &[class*='pos-top'] {
    flex-direction: column;
  }
}

.CollapsibleList {
  @include mixin.getFlexDisplay(column, unset, center, flex-start);
  @include mixin.getFillAvailableWidth();
  @include mixin.getFillAvailableMaxWidth();
  align-self: center;
  opacity: 1;
  padding: 0.5em;
  box-shadow: appProp.$collapsibleListShadow;
  border-radius: 0.3125em;
  gap: 0.25em;
  will-change: transform;
  position: relative;
  content-visibility: auto;
  transform-origin: top;

  &Wrapper {
    @include mixin.getFlexDisplay(column, unset);
    @include mixin.getFillAvailableWidth();
    content-visibility: auto;
    transition: transform 0.2s linear, max-height 0.25s linear;
    transition-behavior: allow-discrete;
    will-change: transform;
    gap: 0.25em;

    &:not([class*='open']) {
      transform: scaleY(0);
      gap: 0;
      max-height: 0;
    }

    &:is([class*='open']) {
      transform: scaleY(1);
      max-height: 1000em;
    }
  }

  @extend %customStyleProps;

  &:not([class*='open']) {
    gap: 0;
  }
}
