@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

.Medal {
  @include mixin.getFlexDisplay(column, wrap, center, flex-start);
  @include mixin.getFillAvailableWidth();
  flex: 1;
  perspective: 1000px;

  &[class*='small'] {
    width: 2.5rem;
  }

  &[class*='medium'] {
    width: 3.5rem;
  }

  &[class*='large'] {
    width: 5rem;
  }

  &[class*='original'] {
    width: 55dvw;
    max-width: 100%;
  }

  &Wrapper {
    transform-style: preserve-3d;
    display: flex;
    position: relative;
    width: inherit;
    aspect-ratio: 1;
  }

  &Image {
    @include mixin.preventImageSelection();
    transform-style: preserve-3d;
    backface-visibility: hidden;
    flex: 1;
    filter: brightness(1.12) drop-shadow(1px 1px 2px black);
    transition: all 0.73s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: inherit;
    position: absolute;
  }

  &FrontImage {
    backface-visibility: hidden;
    background-color: transparent;
  }

  &BackImage {
    backface-visibility: hidden;
    background-color: transparent;
    transform: rotateY(180deg);
  }

  &Info {
    @include mixin.getFlexDisplay(column, wrap, center, center, 1);
    @include mixin.getFillAvailableWidth();
    opacity: 0;
  }

  &FlipButton {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.FlipFrontToBackForward {
  animation: flip-front-to-back-forward 0.55s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;

  .MedalInfo {
    opacity: 1;
    padding: 0 3em;
    text-align: center;
    transform: rotateY(180deg);
    transition: opacity 2s;

    &:first-child {
      margin-top: 2em;
    }
  }
}

.FlipBackToFrontForward {
  animation: flip-back-to-front-forward 0.55s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

.FlipMedalAnimation {
  opacity: 0;
  @include mixin.preventImageSelection();
  @include appAnimation.flipOnX();
}

@media (hover) {
  .MedalImage:hover {
    filter: brightness(1.12) drop-shadow(1px 1px 1px #22222248);
    transition: all 0.73s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}

.MedalImage:active {
  filter: brightness(1) drop-shadow(0 1px 1px rgb(240, 163, 76));
}

@keyframes flip-scale-up-ver {
  0% {
    transform: scale(1) rotateY(0);
  }

  25% {
    transform: scale(2.5) rotateY(90deg);
  }

  50% {
    transform: scale(1) rotateY(180deg);
  }

  100% {
    transform: scale(1) rotateY(180deg);
  }
}

@keyframes flip-front-to-back-forward {
  0% {
    transform: translatez(0) rotateY(0);
  }

  100% {
    transform: rotateY(-180deg);
  }
}

@keyframes flip-back-to-front-forward {
  0% {
    transform: translatez(0) rotateY(-180deg);
  }

  100% {
    transform: rotateY(-360deg);
  }
}
