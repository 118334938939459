@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.AdminHubPanel {
  @include mixin.getFlexDisplay(row, wrap, center, space-between, 1);
  @include mixin.getFillAvailableWidth();
  gap: 1em;
  overflow: visible;
  z-index: 1;
  padding: .5em;
}

.OpenSpot {
  filter: brightness(0.9) saturate(0.9);
  box-shadow: none !important;
}