@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

$fontVarUnfilled: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48;
$fontVarFilled: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;

%notificationFrame {
  content: ' ';
  @include mixin.getFlexDisplay(column, unset, center, center, 1);
  position: absolute;
  border-radius: 0.25em;
}

.StudentCard {
  position: relative;
  @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
  @include mixin.getFillAvailableWidth();
  // @include mixin.preventImageSelection();
  @include mixin.getPopupWoodBg();
  @include mixin.getTransformStyle(flat);
  border-radius: .5em;
  max-width: 15em;
  min-width: 15em;
  width: 100%;
  gap: .5em;
  font-variation-settings: $fontVarUnfilled;
  height: 100%;
  gap: 1em;

  &::before {
    @extend %notificationFrame;
    inset: 0.35em;
    box-shadow: appProp.$blackBoxShadow50;
  }

  &::after {
    content: ' ';
    position: absolute;
    inset: 0.55em;
    box-shadow: appProp.$cellShadow;
  }

  &:hover {
    filter: brightness(1.05);
  }

  &InnerLayout {
    @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
    @include mixin.getFillAvailableWidth();
    padding: 0 .5em;
  }


  &Avatar {
    @include mixin.getFlexDisplay(column, nowrap, center, center);
    @include mixin.preventImageSelection();
    @include mixin.getIconWoodBg();
    box-shadow: appProp.$blackBoxShadow35;
    @include mixin.getTransformStyle(flat);
    padding: 0.2em;
    border-radius: .5em;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.585));
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
}

.HighlightBg {
  filter: brightness(1.2);
}

.ActionButton {
  z-index: 1;
}