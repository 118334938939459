@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.AppFooter {
  @include mixin.getFlexDisplay(column, wrap, center, center);
  @include mixin.getFillAvailableWidth();
  gap: 1em;
  margin-top: 2em;

  &TextRow {
    @include mixin.getFlexDisplay(row, wrap, center, center);
  }
}
