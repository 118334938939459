@use '/src/scss/mixins.module.scss' as mixin;

.Level {

  &Layout {
    @include mixin.getFlexDisplay(column, nowrap, center, flex-start, 1);
    @include mixin.getFillAvailableWidth();
  }

  &Stars {
    @include mixin.getFlexDisplay(row, nowrap, center, center, 1);

    span {
      color: transparent;
      padding-top: 0.05em;
    }

    &[class*='Level_0'] span:nth-child(n) {
      visibility: hidden;
    }

    &[class*='Level_1'] span:nth-child(-n+1),
    &[class*='Level_2'] span:nth-child(-n+2),
    &[class*='Level_3'] span:nth-child(-n+3),
    &[class*='Level_4'] span:nth-child(-n+4),
    &[class*='Level_5'] span:nth-child(n) {
      @include mixin.getFontVariationSettings(1, 600);
    }
  }
}