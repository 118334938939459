@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/animations.module.scss' as appAnimation;

.DynamicList {
  opacity: 1;
  @include mixin.getFlexDisplay(column, nowrap, center, center, 1);

  &.SquareMaxWidth {
    max-width: 9em;
  }

  &Layout {
    @include mixin.getInsetPanel();
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start, 1);
    padding: 0.5em 0.25em;
    align-self: center;
    margin: 0;
    transform-origin: top;
    box-shadow: 0 0.025em 0.3125em 0.0625em rgba(233, 233, 233, 0.2), inset 0 0.1875em 0.35em 0 rgba(38, 21, 3, 0.6);
    background: linear-gradient(rgba(30, 28, 26, 0.07), rgba(5, 5, 5, 0.05));
    position: relative;
    user-select: none;
    border-radius: 0.25em;
    cursor: pointer;
  }

  &Border {
    @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
    @include mixin.getFillAvailableWidth();
    border-radius: 0.25em;
    box-shadow: appProp.$blackBoxShadow35;
    padding: 0.15em;

    &[class*='pass'] {
      @include appAnimation.successAnimationTimeboxed();
    }

    &[class*='fail'] {
      @include appAnimation.shakeAnimationTimeboxed();
    }
  }

  &NumericValue {
    display: block;
    background-color: transparent;
    opacity: 0;
    @include mixin.getAbsoluteCenteredLayout();
  }

  &SwitchSidesBtn {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }

  &Items {
    @include mixin.getFlexDisplay(row, wrap, center, center, 1);
    @include mixin.getFillAvailableWidth();
    gap: 0.32em;
    opacity: 0;

    &.Square {
      aspect-ratio: 1/1;
    }

  }

  &Item {
    @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
    // flex: `0 0 calc((100/$ { props.cardsCount }) * 1% - 16px)`
    aspect-ratio: 1 / 1;
    box-sizing: border-box;
    min-width: 2.45em;
    min-height: 2.45em;
    max-width: 10em;
    max-height: 10em;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    box-shadow: appProp.$cellShadow;
    background-color: appProp.$black015;
    border-radius: 5em;
    position: relative;
    box-sizing: border-box;

    h1 {
      @include mixin.getFontVariationSettings(0, 600);
      top: 0.05em;
      line-height: 1em !important;
    }
  }
}

.DynamicList {
  &[class*='sideFruit'] {
    animation: flip-x 0.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    .DynamicListNumericValue {
      animation: hide-on-flip 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .DynamicListItems {
      animation: show-on-flip 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.25s both;
    }
  }

  &[class*='sideNumber'] {
    animation: unflip-x 0.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    .DynamicListNumericValue {
      animation: show-on-flip 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.25s both;
    }

    .DynamicListItems {
      animation: hide-on-flip 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  &[class*='sideNone'] {
    .DynamicListItems {
      opacity: 1;
    }
  }
}