@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.CareerHub {
 @include mixin.getInsetPanel();
  gap: 0.25em;
  min-width: 16em;
  min-height: 16em;

  .FillWidth {
    margin-bottom: 0.5em;
    &>h1 {
      @include mixin.getFillAvailableWidth();
    }
  }

  @media only screen and (max-width: 864px) {
    min-width: 25.5em;
    min-height: 16em;
  }

  &Container {
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start);
    @include mixin.getFillAvailableWidth();
    gap: 1em;
  }

  &Row {
    @include mixin.getFlexDisplay(row, nowrap, flex-start, flex-start);
      @include mixin.getFillAvailableWidth();
      gap: 0.5em;
  }

  &RowProgress {
    &::before {
      content: '';
      position: absolute;
      background-color: appProp.$primaryColor25;
      max-width: 100%;
      width: 100%;
      height: 62%;
      left: 0.5em;
      right: 0.5em;
      color: appProp.$primaryColor80;
      border: 0.125px groove transparent;
      border-radius: 0.1875em;
      box-shadow: appProp.$blackBoxShadow50;
    }
  }

  .ActionButtons {
    @include mixin.getActionButtonLayout();
  }
}

@include mixin.getProgressBarWidthPercentages(CareerHubRowProgress);