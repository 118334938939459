@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.MeTabs {
  @include mixin.getFlexDisplay(row, nowrap, center, space-around, 1);
  @include mixin.getFillAvailableWidth();
  margin: 0 0 1em;
  border-bottom: thin solid appProp.$woodAltColor50;
  position: relative;

  &ActiveIndicator {
    transition: all cubic-bezier(0.68, -0.25, 0.265, 1.1) 0.75s;
    position: absolute;
    bottom: 0;
    border-bottom: 0.25em solid appProp.$orange100;
    z-index: 1;
  }

  &Item {
    border-bottom: 0.25em solid transparent;
    padding: 0 0.25em 0.0625em;
    cursor: pointer;
    @include mixin.preventImageSelection();

    >div {
      @include mixin.getFlexDisplay(row, nowrap, center, center);
      gap: 0.1em;
    }
  }

  @media (hover) {
    .MeTabsItem:hover {
      border-bottom: 0.25em solid appProp.$primaryColor30;
    }
  }
}
