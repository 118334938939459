@use '/src/scss/variables.module.scss' as appProp;
@use 'sass:list';

//#region MIXINS

/// Fades in element animation
///
/// @param {string} $direction row / column
/// @param {string} $wrap flex-wrap, defaults to unset
/// @param {string} $align align-items, defaults to unset
/// @param {string} $justify justify-content, defaults to unset
/// @param {string} $flex flex, defaults to unset
///   Number of times to run the animation
@mixin getFlexDisplay($direction, $wrap: unset, $align: unset, $justify: unset, $flex: unset) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  align-items: $align;
  justify-content: $justify;
  flex: $flex;
}

@mixin getFillAvailableWidth() {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}

@mixin getFillAvailableMaxWidth() {
  max-width: 100%;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}

@mixin getTransformStyle($style) {
  transform-style: $style;
  -webkit-transform-style: $style;
}

@mixin getSceneBodyLayout() {
  @include getFlexDisplay(column, wrap, center, flex-start, 1);
  @include getFillAvailableWidth();
  position: relative;
  margin: 1em;
  max-width: 50em;
  align-self: center;
  gap: 1em;

  /*****************************
    Laptop
    *****************************/
  @media only screen and (max-width: 1440px) {
    .CareerLayout {
      max-width: 80em;
    }
  }

  /*****************************
  Laptop
  *****************************/
  @media only screen and (max-width: 1024px) {
    .CareerLayout {
      max-width: 64em;
    }
  }

  /*****************************
  iPad Mini
  *****************************/
  @media only screen and (max-width: 768px) {
    .CareerLayout {
      max-width: 48em;
    }
  }

  /*****************************
  Pixel 6a
  *****************************/
  @media only screen and (max-width: 428px) {
    .CareerLayout {
      max-width: 26em;
    }
  }

  /*****************************
  Older Pixel 2, or 3
  *****************************/
  @media only screen and (max-width: 375px) {
    .CareerLayout {
      max-width: 23em;
    }
  }

  /*****************************
  Smallest resolution
  *****************************/
  @media only screen and (max-width: 320px) {
    .CareerLayout {
      max-width: 20em;
    }
  }
}

@mixin getRaisedPanelSansFont() {
  border-radius: 0.25em;
  box-shadow: appProp.$blackBoxShadow35;
}

@mixin getRaisedPanel() {
  border-radius: 0.25em;
  box-shadow: appProp.$blackBoxShadow35;
  @include getFontVariationSettings(0, 600);
}

@mixin getInsetPanel() {
  @include getFlexDisplay(column, wrap, center, flex-start, 1);
  @include getFillAvailableMaxWidth();
  @include getFillAvailableWidth();
  user-select: none;
  min-height: 3em;
  padding: 0.5em;
  box-shadow: appProp.$insetShadow;
  // background-color: appProp.$cellBackground;
  // backdrop-filter: brightness(1.15);
  border-radius: 0.3125em;
  margin-bottom: 0.15em;
}

@mixin getInsetSpan() {
  background-color: appProp.$cellBackground;
  padding: 0.35em;
  box-shadow: 0 0.125em 0.3125em 0.0625em rgba(233, 233, 233, 0.2), inset 0 0.15em 0.2em 0 rgba(38, 21, 3, 0.4);
  border-radius: 0.1752em;
}

@mixin getInsetFontStyle() {
  background-color: appProp.$woodColor100;
  text-shadow: 2px 2px 3px appProp.$white25;
  line-height: 1;
  color: transparent;
  background-clip: text;
  position: relative;
}

@mixin getInsetFont() {
  background-color: appProp.$woodColor100;
  text-shadow: 2px 2px 3px appProp.$white25;
  color: transparent;
  background-clip: text;
}

@mixin getFontVariationSettings($fill: 0, $wght: 500, $grad: 200, $opsz: 48) {
  font-variation-settings: 'FILL' $fill, 'wght' $wght, 'GRAD' $grad, 'opsz' $opsz;
}

@mixin getFontVariationSettingsImportant($fill: 0, $wght: 500, $grad: 200, $opsz: 48) {
  font-variation-settings: 'FILL' $fill, 'wght' $wght, 'GRAD' $grad, 'opsz' $opsz !important;
}

@mixin getInsetColorAndFontSettings($color: $woodColor100, $fill: null, $wght: 500, $grad: 200, $opsz: 48) {
  @if $color {
    background-color: $color;
  }

  color: transparent;

  > span[class*='material-symbols-outlined'] {
    color: transparent;

    @if $fill {
      @include getFontVariationSettings($fill, $wght, $grad, $opsz);
    }
  }
}

@mixin getProgressBarWidthPercentages($rowCls) {
  @for $i from 0 through 99 {
    @if ($i < 10) {
      [class*='width-00#{$i}'] {
        &.#{$rowCls} {
          &::before {
            transition: all 1.5s ease-in;
            @if $i == 0 {
              width: calc(1% - 0.65rem);
            } @else {
              width: calc($i * 1% - 0.65rem);
            }
          }
        }
      }
    } @else {
      [class*='width-0#{$i}'] {
        &.#{$rowCls} {
          &::before {
            @include getFillAvailableMaxWidth();
            width: calc($i * 1% - 0.65rem);
            transition: all 1.5s ease-in;
          }
        }
      }
    }
  }
}

@mixin getCarouselStripeCls() {
  @for $i from 1 through 20 {
    span.Count_#{$i} {
      max-width: calc(7.3em * $i + 0.4em * ($i - 1));
    }
  }
}

@mixin getCellDimensionsForScreenSize() {
  @include getFlexDisplay(column, nowrap, center, center);
  width: 100%;
  @include getFillAvailableWidth();
  aspect-ratio: 1 / 1;
  @include getFlexBasisColumns();
  min-width: 2.45em;
  min-height: 2.45em;
  max-width: 10em;
  max-height: 10em;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: appProp.$cellShadow;
  border-radius: 5em;
  position: relative;

  h1 {
    @include getFontVariationSettings(0, 600);
    // @include getFlexDisplay(column, nowrap, center, center);
    top: 0.05em;
  }
}

@mixin preventImageSelection() {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin getImageAsDivBackground($imageUrl, $width: auto, $height: auto, $position: no-repeat, $size: cover) {
  background-image: url('/images/' + $imageUrl);
  background-position: center center;
  background-repeat: $position;
  background-size: $size;
  width: $width;
  height: $height;
  @include getFontVariationSettings(0, 700);
}

@mixin getGlass() {
  background: appProp.$white20;
  border: appProp.$thickWoodenBorder;
  border-radius: 1em;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 30px appProp.$black10;
}

@mixin getWoodMenu() {
  border: appProp.$thickWoodenBorder;
  box-shadow: -0.1875em 0.1875em 0.75em -0.0625em rgb(108 52 13 / 87.8%);
  background: content-box url('/images/menu-bg-250x500.webp') no-repeat center center;
  background-size: cover;
  backdrop-filter: blur(5px);
}

@mixin getPopupWoodBg() {
  border: appProp.$thickWoodenBorder;
  box-shadow: 0.0875em 0.0875em 0.45em 0.0625em rgb(108 52 13 / 57.8%);
  background: content-box url('/images/bg-wood.webp') no-repeat center center;
  background-size: cover;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

@mixin getIconWoodBg() {
  box-shadow: 0.0875em 0.0875em 0.75em 0.0625em rgb(108 52 13 / 57.8%);
  background: content-box url('/images/bg-icon-wood.webp') no-repeat center center;
  background-size: cover;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

@mixin getTutorialBaseStyles() {
  &Display {
    @include getFlexDisplay(column, unset, center, flex-start, 1);
    @include getFillAvailableWidth();
    position: relative;
    gap: 1em;

    &Row {
      > img {
        vertical-align: middle;
      }
    }
  }

  &Definition {
    @include getInsetPanel();
    @include getFlexDisplay(row, nowrap, center, center);
    padding: 1.5em 1em;
    gap: 0.5em;
  }

  &Row {
    @include getFlexDisplay(row, unset, center, center);
    @include getFillAvailableWidth();
    gap: 1.25em;
    margin: 0.75em 0;
  }
}

@mixin getManageSceneBaseLayout() {
  @include getFlexDisplay(column, unset, center, flex-start, 1);
  @include getFillAvailableWidth();
  position: relative;
  gap: 3em;
  max-width: appProp.$sceneMaxWidth;
}

@mixin getActionButtonLayout() {
  @include getFlexDisplay(row, nowrap, center, space-between, 1);
  @include getFillAvailableWidth();
}

@mixin getDefaultListStyle() {
  ol,
  ul {
    margin: 0 0 0 1em;
    font-size: 1.5em;
    @include getFontVariationSettings(0, 600);
  }

  li {
    list-style-type: disc;
    line-height: 110%;
    margin-bottom: 0.5em;

    ul {
      margin: 0.5em 0;
      font-size: 1em;
      @include getFontVariationSettings(0, 600);

      li {
        list-style-type: none;
      }
    }
  }
}

@mixin getSelectedInteractiveMathField() {
  z-index: 1;

  span {
    @include getFontVariationSettingsImportant(1, 500);
  }

  [class*='Measure'] {
    opacity: 1;
    color: rgb(85 42 0 / 75%) !important;
    animation: fade-in-animation 1.6s linear forwards;
  }
}

@mixin getAbsoluteCenteredLayout($singleline: true) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @if $singleline {
    white-space: nowrap;
  }
}

@mixin getFlexBasisColumns() {
  @for $i from 1 through 11 {
    &.columnNum#{$i} {
      @include getFlexBasisForColumnCount($i);
    }
  }
}

@mixin getHighlight($brightNumber: 1.15) {
  backdrop-filter: brightness($brightNumber);
}

@mixin getHelpPanelBaseStyle() {
  @include getFlexDisplay(column, wrap, center, center);
  padding: 0.15em;
  cursor: pointer;

  @media (hover) {
    &:hover {
      [class*='me-icon-cls'][class*='material-symbols-outlined'] {
        @include getFontVariationSettings(1, 500);
      }
    }
  }

  &:active {
    border-radius: 0.25em;
    outline: thin solid appProp.$primaryColor;
  }
}

%horizontalCenter {
  left: 50%;
  transform: translateX(-50%);
}

// #region ********************** FONT AND FONT PROPS ***********************************

%UpperCaseWithShadow {
  text-transform: uppercase;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.275);
}

@mixin getElementFontSizes() {
  &[class*='xs5'][class*='InsetText'] {
    font-size: 0.5em;
    @extend %UpperCaseWithShadow;
  }

  &[class*='xs6'][class*='InsetText'] {
    font-size: 0.6em;
    @extend %UpperCaseWithShadow;
  }

  &[class*='xs75'][class*='InsetText'] {
    font-size: 0.75em;
    @extend %UpperCaseWithShadow;
  }

  &[class*='xs9'][class*='InsetText'] {
    font-size: 0.9em;
    @include getFontVariationSettings(1, 900);
    @extend %UpperCaseWithShadow;
  }

  &[class*='xs1'][class*='InsetText'] {
    font-size: 1em;
    @extend %UpperCaseWithShadow;
  }

  &[class*='xs1'][class*='InsetText'] {
    font-size: 1.1em;
    @extend %UpperCaseWithShadow;
  }

  &[class*='xs12'][class*='InsetText'],
  &[class*='xs12'][class*='Icon'] {
    font-size: 1.2em;
    @include getFontVariationSettings(1, 900);
    @extend %UpperCaseWithShadow;
  }

  &[class*='xs145'][class*='InsetText'] {
    font-size: 1.45em;
    @include getFontVariationSettings(1, 700);
    @extend %UpperCaseWithShadow;
  }

  &[class*='xs15'][class*='InsetText'] {
    font-size: 1.5em;
    @include getFontVariationSettings(1, 600);
    @extend %UpperCaseWithShadow;
  }

  &[class*='xxs'][class*='InsetText'] {
    font-size: 1.15em;
    @extend %UpperCaseWithShadow;
  }

  &[class*='xsm'][class*='InsetText'] {
    font-size: 0.9em;
    @extend %UpperCaseWithShadow;
  }

  &[class*='sma'][class*='InsetText'] {
    font-size: 1.5em;
    @include getFontVariationSettings(0, 500);
  }

  &[class*='sma'][class*='InsetText'][class*='Icon'] {
    font-size: 1.5em;
    @include getFontVariationSettings(0, 500);
  }

  &[class*='sam'][class*='InsetText'] {
    font-size: 1.65em;
    // line-height: 1.3em;
    @include getFontVariationSettings(0, 600);
  }

  &[class*='med'][class*='InsetText'] {
    font-size: 1.75em;
    line-height: 1.143em;
    @include getFontVariationSettings(0, 600);
  }

  &[class*='mel'][class*='InsetText'] {
    font-size: 2em;
    line-height: 1.2em;
    @include getFontVariationSettings(0, 700);
  }

  &[class*='mlg'][class*='InsetText'] {
    font-size: 2.35em;
    line-height: 1.2em;
    @include getFontVariationSettings(0, 700);
  }

  &[class*='lge'][class*='InsetText'] {
    font-size: 2.54em;
    line-height: 1.5em;
    @include getFontVariationSettings(0, 700);
  }

  &[class*='xtl'][class*='InsetText'] {
    font-size: 3em;
    line-height: 1.5em;
    @include getFontVariationSettings(0, 800);
  }

  &[class*='xxl'][class*='InsetText'] {
    font-size: 4em;
    line-height: 1.5em;
    @include getFontVariationSettings(0, 800);
  }

  &[class*='xl3'][class*='InsetText'] {
    font-size: 3em;
    line-height: 1em;
    @include getFontVariationSettings(0, 800);
  }

  &[class*='xl4'][class*='InsetText'] {
    font-size: 4em;
    line-height: 1em;
    @include getFontVariationSettings(0, 800);
  }

  &[class*='xl5'][class*='InsetText'] {
    font-size: 5em;
    line-height: 1.5em;
    @include getFontVariationSettings(1, 900);
  }

  &[class*='xl6'][class*='InsetText'] {
    font-size: 6em;
    line-height: 1.5em;
    @include getFontVariationSettings(1, 900);
  }

  &[class*='xl7'][class*='InsetText'] {
    font-size: 7em;
    line-height: 1.5em;
    @include getFontVariationSettings(0, 900);
  }

  &[class*='xl8'][class*='InsetText'] {
    font-size: 8em;
    line-height: 1.5em;
    @include getFontVariationSettings(1, 900);
  }

  &[class*='xl9'][class*='InsetText'] {
    font-size: 9em;
    line-height: 1.2em;
    @include getFontVariationSettings(1, 900);
  }

  &[class*='xl10'][class*='InsetText'] {
    font-size: 10em;
    // line-height: 1.5em;
    @include getFontVariationSettings(1, 900);
  }

  &[class*='material-symbols-outlined'][class*='bold900'],
  &[class*='InsetText'][class*='bold900'] {
    @include getFontVariationSettings(0, 900);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 900);
    }
  }

  &[class*='material-symbols-outlined'][class*='bold800'],
  &[class*='InsetText'][class*='bold800'] {
    @include getFontVariationSettings(0, 800);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 800);
    }
  }

  &[class*='material-symbols-outlined'][class*='bold700'],
  &[class*='InsetText'][class*='bold700'] {
    @include getFontVariationSettings(0, 700);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 700);
    }
  }

  &[class*='material-symbols-outlined'][class*='bold600'],
  &[class*='InsetText'][class*='bold600'] {
    @include getFontVariationSettings(0, 600);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 600);
    }
  }

  &[class*='material-symbols-outlined'][class*='bold500'],
  &[class*='InsetText'][class*='bold500'] {
    @include getFontVariationSettings(0, 500);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 500);
    }
  }

  &[class*='material-symbols-outlined'][class*='bold400'],
  &[class*='InsetText'][class*='bold400'] {
    @include getFontVariationSettings(0, 400);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 400);
    }
  }

  &[class*='material-symbols-outlined'][class*='bold300'],
  &[class*='InsetText'][class*='bold300'] {
    @include getFontVariationSettings(0, 300);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 300);
    }
  }

  &[class*='material-symbols-outlined'][class*='bold200'],
  &[class*='InsetText'][class*='bold200'] {
    @include getFontVariationSettings(0, 200);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 200);
    }
  }

  &[class*='material-symbols-outlined'][class*='bold100'],
  &[class*='InsetText'][class*='bold100'] {
    @include getFontVariationSettings(0, 100);

    &[class*='IconFilled'] {
      @include getFontVariationSettings(1, 100);
    }
  }
}

$columnPercentages: 100, 50, 33.3, 25, 20, 16.66, 14.27, 12.5, 11.11, 10, 9.09;

@mixin getFlexBasisForColumnCount($count) {
  flex: 0 0 calc(#{list.nth($columnPercentages, $count)} * 1% - 2px);
  // $col11Percentage: 9.09;
  // $col10Percentage: 10;
  // $col9Percentage: 11.11;
  // $col8Percentage: 12.5;
  // $col7Percentage: 14.27;
  // $col6Percentage: 16.66;
  // $col5Percentage: 20;
  // $col4Percentage: 25;
  // $col3Percentage: 33.3;
  // $col2Percentage: 50;
  // $col1Percentage: 100;
}

$fontWeightValues: 100, 200, 300, 400, 500, 600, 700, 800, 900;

@mixin getFontWeightsWithoutFill() {
  @each $wght in $fontWeightValues {
    &[class*='bold#{$wght}'] {
      @include getFontVariationSettings(0, $wght);
    }
  }

  // #region EXAMPLE OF OUTPUT
  // &[class*='bold900'] {
  //   @include getFontVariationSettings(0, 900);
  // }

  // &[class*='bold800'] {
  //   @include getFontVariationSettings(0, 800);
  // }

  // &[class*='bold700'] {
  //   @include getFontVariationSettings(0, 700);
  // }

  // &[class*='bold600'] {
  //   @include getFontVariationSettings(0, 600);
  // }

  // &[class*='bold500'] {
  //   @include getFontVariationSettings(0, 500);
  // }

  // &[class*='bold400'] {
  //   @include getFontVariationSettings(0, 400);
  // }

  // &[class*='bold300'] {
  //   @include getFontVariationSettings(0, 300);
  // }

  // &[class*='bold200'] {
  //   @include getFontVariationSettings(0, 200);
  // }

  // &[class*='bold100'] {
  //   @include getFontVariationSettings(0, 100);
  // }
  // #endregion
}

@mixin getFontWeightsWithFill() {
  @each $wght in $fontWeightValues {
    &[class*='bold#{$wght}'] {
      @include getFontVariationSettings(1, $wght);
    }
  }

  // #region EXAMPLE OF OUTPUT

  // &[class*='bold900'] {
  //   @include getFontVariationSettings(1, 900);
  // }

  // &[class*='bold800'] {
  //   @include getFontVariationSettings(1, 800);
  // }

  // &[class*='bold700'] {
  //   @include getFontVariationSettings(1, 700);
  // }

  // &[class*='bold600'] {
  //   @include getFontVariationSettings(1, 600);
  // }

  // &[class*='bold500'] {
  //   @include getFontVariationSettings(1, 500);
  // }

  // &[class*='bold400'] {
  //   @include getFontVariationSettings(1, 400);
  // }

  // &[class*='bold300'] {
  //   @include getFontVariationSettings(1, 300);
  // }

  // &[class*='bold200'] {
  //   @include getFontVariationSettings(1, 200);
  // }

  // &[class*='bold100'] {
  //   @include getFontVariationSettings(1, 100);
  // }
  // }
  // #endregion
}

// #endregion

// #region ********************** NEW ME_TEXT FONT AND PROPS
%upperCaseText {
  text-transform: uppercase;
}

@mixin getNewFontStyle($clsName) {
  & {
    // font-family: 'Big Shoulders Display', 'BigShouldersDisplay', sans-serif;
    line-height: 1;
    position: relative;
    color: appProp.$woodColor100;

    // #region TEXT FONT-STYLE **********************************************************

    &[class*='Capitalize'] {
      text-transform: capitalize;
    }

    &[class*='Uppercase'] {
      @extend %upperCaseText;
    }

    &[class*='Selected'] {
      @include getFontVariationSettings(0, 800);
    }

    &[class*='SingleLine'] {
      white-space: nowrap;
    }

    // #endregion

    // #region TEXT FONT-SIZE ***********************************************************
    &[class*='xs5'] {
      font-size: 0.5rem;
      @extend %upperCaseText;
    }

    &[class*='xs6'] {
      font-size: 0.6rem;
      @extend %upperCaseText;
    }

    &[class*='xs75'] {
      font-size: 0.75rem;
      @extend %upperCaseText;
    }

    &[class*='xs9'] {
      font-size: 0.9rem;
      @extend %upperCaseText;
    }

    &[class*='xs1'] {
      font-size: 1rem;
      @extend %upperCaseText;
    }

    &[class*='xs11'] {
      font-size: 1.1rem;
      @extend %upperCaseText;
    }

    &[class*='xs12'] {
      font-size: 1.2rem;

      @extend %upperCaseText;
    }

    &[class*='xs145'] {
      font-size: 1.45rem;
      @extend %upperCaseText;
    }

    &[class*='xs15'] {
      font-size: 1.5rem;
      @extend %upperCaseText;
    }

    &[class*='xss'] {
      font-size: 1.15rem;
      @extend %upperCaseText;
    }

    &[class*='xsm'] {
      font-size: 0.9rem;
      @extend %upperCaseText;
    }

    &[class*='sms'] {
      font-size: 0.9rem;
    }

    &[class*='sma'] {
      font-size: 1.5rem;
    }

    &[class*='sma'] {
      font-size: 1.5rem;
    }

    &[class*='sam'] {
      font-size: 1.65rem;
    }

    &[class*='med'] {
      font-size: 1.75rem;
      line-height: 1.143em;
    }

    &[class*='mel'] {
      font-size: 2rem;
      line-height: 1.2em;
    }

    &[class*='mlg'] {
      font-size: 2.35rem;
      line-height: 1.2em;
    }

    &[class*='lge'] {
      font-size: 2.54rem;
      line-height: 1.5em;
    }

    &[class*='xtl'] {
      font-size: 3rem;
      line-height: 1.5em;
    }

    &[class*='xxl'] {
      font-size: 4rem;
      line-height: 1.5em;
    }

    &[class*='xl3'] {
      font-size: 3rem;
      line-height: 1em;
    }

    &[class*='xl35'] {
      font-size: 3.5rem;
      line-height: 1em;
    }

    &[class*='xl4'] {
      font-size: 4rem;
      line-height: 1em;
    }

    &[class*='xl5'] {
      font-size: 5rem;
      line-height: 1.5em;
    }

    &[class*='xl6'] {
      font-size: 6rem;
      line-height: 1.5em;
    }

    &[class*='xl7'] {
      font-size: 7rem;
      line-height: 1.5em;
    }

    &[class*='xl8'] {
      font-size: 8rem;
      line-height: 1.5em;
    }

    &[class*='xl9'] {
      font-size: 9rem;
      line-height: 1.2em;
    }

    &[class*='xl10'] {
      font-size: 10rem;
    }

    &[class*='xl15'] {
      font-size: 15rem;
    }

    &[class*='xl20'] {
      font-size: 20rem;
    }

    // #endregion

    // #region TEXT LINE-HEIGHT *********************************************************
    &[class*='line1'] {
      line-height: 1em;
    }

    &[class*='line11'] {
      line-height: 1.1em;
    }

    &[class*='line12'] {
      line-height: 1.2em;
    }

    &[class*='line13'] {
      line-height: 1.3em;
    }

    &[class*='line14'] {
      line-height: 1.4em;
    }

    &[class*='line15'] {
      line-height: 1.5em;
    }

    &[class*='line16'] {
      line-height: 1.6em;
    }

    &[class*='line17'] {
      line-height: 1.7em;
    }

    &[class*='line18'] {
      line-height: 1.8em;
    }

    &[class*='line19'] {
      line-height: 1.9em;
    }

    &[class*='line2'] {
      line-height: 2em;
    }

    // #endregion

    // #region TEXT WEIGHT **************************************************************
    &[class*='bold900'] {
      @include getFontVariationSettings(0, 900);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 900);
      }
    }

    &[class*='bold800'] {
      @include getFontVariationSettings(0, 800);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 800);
      }
    }

    &[class*='bold700'] {
      @include getFontVariationSettings(0, 700);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 700);
      }
    }

    &[class*='bold600'] {
      @include getFontVariationSettings(0, 600);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 600);
      }
    }

    &[class*='bold500'] {
      @include getFontVariationSettings(0, 500);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 500);
      }
    }

    &[class*='bold400'] {
      @include getFontVariationSettings(0, 400);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 400);
      }
    }

    &[class*='bold300'] {
      @include getFontVariationSettings(0, 300);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 300);
      }
    }

    &[class*='bold200'] {
      @include getFontVariationSettings(0, 200);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 200);
      }
    }

    &[class*='bold100'] {
      @include getFontVariationSettings(0, 100);

      &[class*='filled'] {
        @include getFontVariationSettings(1, 100);
      }
    }

    // #endregion
  }
}
// #endregion

// #endregion
//#endregion
