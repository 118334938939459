@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/mixins.module.scss' as mixin;
@use "sass:selector";

$mathSignFontSize: 1.8em;

.MathSign {
  font-size: $mathSignFontSize;
  color: appProp.$woodColor100;
  @include mixin.getFontVariationSettings(0, 900);
  line-height: unset;

  &InsetStyle {
    @include mixin.getInsetFontStyle();
    @include mixin.getFontVariationSettings(0, 900);
  }

  &Special[class*="material-symbols-outlined"] {
    font-size: $mathSignFontSize;
  }
}