@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;


$borderColor: appProp.$primaryColor;
$borderSize: 3px;
$ringHalfDisappearDuration: 2s;
$boxQuarterDisappearDuration: 1s;
$boxHalfDisappearDuration: 2s;
$boxThreeQuarterDisappearDuration: 3s;

.Circle {
  border-radius: 50%;
}

.Square {
  border-radius: .25em;
}

.RightRing,
.LeftRing {
  width: 100%;
  height: 100%;
  rotate: -45deg;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 50%;
  border: $borderSize solid transparent;
}

.LeftRing {
  border-color: $borderColor transparent transparent $borderColor;
}

.RightRing {
  border-color: transparent $borderColor $borderColor transparent;
}

.TopBox,
.RightBox,
.BottomBox,
.LeftBox {
  position: absolute;
  width: inherit;
  height: inherit;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 0.25em;
  border: $borderSize solid transparent;
}

.TopBox {
  border-color: $borderColor transparent transparent transparent;
}

.RightBox {
  border-color: transparent $borderColor transparent transparent;
}

.BottomBox {
  border-color: transparent transparent $borderColor transparent;
}

.LeftBox {
  border-color: transparent transparent transparent $borderColor;
}


.LeftClipped,
.RightClipped {
  @include mixin.getFlexDisplay(row, nowrap, center, center, 1);
  position: absolute;
  width: inherit;
  height: inherit;
  box-sizing: border-box;
  z-index: 1;
}

.LeftClipped {
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
}

.RightClipped {
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}

.Ring {
  &Box {
    @include mixin.getFlexDisplay(row, nowrap, center, center);
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    position: absolute;
    z-index: 1;
    opacity: 0;

    &.Circle {
      border-radius: 50%;
    }

    &.Square {
      border-radius: .25em;
    }

    &.RingAnimation {
      opacity: 1;
    }
  }

  &Animation {
    &.Circle {
      .LeftRotation {
        opacity: 1;
        animation: rotate-left $ringHalfDisappearDuration linear $ringHalfDisappearDuration 1 both;
      }

      .RightRotation {
        opacity: 1;
        animation: rotate-right $ringHalfDisappearDuration linear 1 both;
      }
    }

    &.Square {
      .TopRotation {
        animation: hide-top-border $boxQuarterDisappearDuration linear 1 both;
        transform-origin: right;
      }

      .RightRotation {
        animation: hide-right-border $boxQuarterDisappearDuration linear $boxQuarterDisappearDuration 1 both;
        transform-origin: bottom;
      }

      .BottomRotation {
        transform-origin: left;
        animation: hide-bottom-border $boxQuarterDisappearDuration linear $boxHalfDisappearDuration 1 both;
      }

      .LeftRotation {
        transform-origin: top;
        animation: hide-left-border $boxQuarterDisappearDuration linear $boxThreeQuarterDisappearDuration 1 both;
      }
    }
  }
}

@keyframes rotate-left {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotate-right {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes hide-top-border {
  0% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(0, 1, 1);
  }
}

@keyframes hide-right-border {
  0% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(1, 0, 1);
  }
}

@keyframes hide-bottom-border {
  0% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(0, 1, 1);
  }
}

@keyframes hide-left-border {
  0% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(1, 0, 1);
  }
}