@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.DescriptionPanel {
  @include mixin.getFlexDisplay(column, wrap, center, flex-start, 1);
  @include mixin.getFillAvailableWidth();
  backdrop-filter: blur(10px) brightness(1.25) saturate(1.5);
  box-shadow: appProp.$blackBoxShadow50;
  position: absolute;
  margin: 1em;
  bottom: 0;
  border-radius: .35em;

  &Text {
    padding: .75em;
  }
}