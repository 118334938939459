@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.CopyToClipboardIcon {
  cursor: pointer;
  opacity: .5;

  &:hover {
    opacity: .75;
  }

  &:active {
    opacity: 1;
  }
}