@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

$maxHeight: 35em;

.CapturePopup {
  overflow: hidden;
  max-height: $maxHeight;

  &BodyPanel {
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    max-height: calc($maxHeight - 5em);
    min-width: 30em;
    // background-color: rgb(181 135 77 / 30%);
    // gap: 1em;
  }

  &Row {
    @include mixin.getFlexDisplay(row, nowrap, baseline, space-between, 1);
    @include mixin.getFillAvailableWidth();
  }

  &UserInfo {
    @include mixin.getFlexDisplay(column, nowrap, flex-start, flex-start, 1);
    @include mixin.getFillAvailableWidth();
    gap: 0.25em;
  }
}