@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;


.Spotlight {
  position: absolute;
  @include mixin.getFlexDisplay(column, nowrap, center, center);
  transition: all .5s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  transform-origin: center;
  z-index: appProp.$helpPanelSpotZ;
  pointer-events: all;

  &Glow {
    position: absolute;
    backdrop-filter: appProp.$primaryFilterBrightness;
    inset: 3px;
    border: none;
  }

  &CircleShape {
    transform: scale(2);
    border-radius: 50%;
    border: none;

    .SpotlightGlow {
      border-radius: 50%;
    }
  }

  &SquareShape {
    transform: scale3d(1.05, 1.05, 1);
    transform-origin: center;
    border: none;
    border-radius: .25em;

    .SpotlightGlow {
      border-radius: .25em;
    }
  }

  &Show {
    animation: spark .45s cubic-bezier(0.895, 0.030, 0.685, 0.220) 1 both;
  }

  &Hide {
    animation: hide-spot 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1 both;
  }

  &IconDisplay {
    animation: move-up-down 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 3s 2 both;
  }
}

.ClippedBox {
  position: absolute;
  width: 100vw;
  height: 100vh;
  clip-path: (0 0, 100% 0, 100% 100%, 0 100%);
}

@keyframes hide-spot {

  0% {
    opacity: 1;
  }

  99% {
    z-index: 1;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes move-up-down {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  54% {
    transform: translateY(8px);
  }

  80% {
    transform: translateY(13px);
  }

}

@keyframes spark {

  0% {
    backdrop-filter: brightness(.9);
    opacity: 0;
  }

  40% {
    opacity: 1;
    backdrop-filter: appProp.$sparkMaxBrightness;
  }

  100% {
    backdrop-filter: brightness(1.3);
  }

}