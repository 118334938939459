@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.HelpPopup {
  &Panel {
    @include mixin.getFlexDisplay(column, wrap, center, flex-start);
    @include mixin.getPopupWoodBg();
    pointer-events: all;
    border-radius: 0.5em;
    position: fixed;
    @extend %horizontalCenter;
    z-index: appProp.$helpPanelZ;
    width: 85%;
    max-width: 40em;
    transform-origin: bottom;
    opacity: 0;
    overflow: hidden;
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;

    &Show[class*='HelpPopupPanel'] {
      @extend %horizontalCenter;
    }

    &Show[class*='HelpPopupPanel'][class*='PositionBottom'] {
      bottom: 1em;
      animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both;
    }

    &Show[class*='HelpPopupPanel'][class*='PositionTop'] {
      top: 1em;
      animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both;
    }

    &Hide[class*='HelpPopupPanel'][class*='PositionBottom'] {
      bottom: -200em;
      animation: slide-out-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s 1 normal both;
    }
    &Hide[class*='HelpPopupPanel'][class*='PositionTop'] {
      top: -200em;
      animation: slide-out-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s 1 normal both;
    }

    &Init {
      opacity: 0;
      @extend %horizontalCenter;
      bottom: -200em;
    }
  }

  &Container {
    @include mixin.getFlexDisplay(column, wrap, center, flex-start);
    @include mixin.getFillAvailableWidth();
    margin: 1em 1em 0;
  }
}

.DescriptionText {
  margin-bottom: 0.5em;
}

@mixin hCenterAlign {
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  animation-timing-function: ease-in;
}

// #region ANIMATIONS *******************************************************************

$baseUnit: 1em;

// SHOW PANEL
@keyframes slide-in-bottom {
  0% {
    @include hCenterAlign();
    bottom: -200em;
  }

  45% {
    @include hCenterAlign();
    bottom: -1em + $baseUnit;
  }

  75% {
    @include hCenterAlign();
    bottom: -0.75em + + $baseUnit;
  }

  30%,
  65%,
  90% {
    @include hCenterAlign();
    bottom: 0.75em + $baseUnit;
  }
  100% {
    @include hCenterAlign();
    bottom: var(--help-popup-offset);
  }
}
// HIDE PANEL
@keyframes slide-out-bottom {
  0% {
    @include hCenterAlign();
    bottom: $baseUnit;
  }

  7% {
    @include hCenterAlign();
    bottom: 1em + $baseUnit;
  }

  15% {
    @include hCenterAlign();
    bottom: -1em + $baseUnit;
  }

  23% {
    @include hCenterAlign();
    bottom: 0.5em + $baseUnit;
  }

  100% {
    @include hCenterAlign();
    bottom: -200em;
  }
}

@keyframes slide-in-top {
  0% {
    @include hCenterAlign();
    top: -200em;
  }

  45% {
    @include hCenterAlign();
    top: -1em + $baseUnit;
  }

  75% {
    @include hCenterAlign();
    top: -0.75em + + $baseUnit;
  }

  30%,
  65%,
  90% {
    @include hCenterAlign();
    top: 0.75em + $baseUnit;
  }
  100% {
    @include hCenterAlign();
    top: var(--help-popup-offset);
  }
}
// HIDE PANEL
@keyframes slide-out-top {
  0% {
    @include hCenterAlign();
    top: $baseUnit;
  }

  7% {
    @include hCenterAlign();
    top: 1em + $baseUnit;
  }

  15% {
    @include hCenterAlign();
    top: -1em + $baseUnit;
  }

  23% {
    @include hCenterAlign();
    top: 0.5em + $baseUnit;
  }

  100% {
    @include hCenterAlign();
    top: -200em;
  }
}
// #endregion
