@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

@mixin activeStateStyle() {
  box-shadow: 0 2px 4px 1px rgb(233 233 233 / 20%), inset 0 2px 8px 2px rgb(38 21 3 / 60%);
  background-color: rgb(0 0 0 / 5%);
  transition: box-shadow 0.3s ease-in-out;
}

@mixin hoverStateStyle() {
  box-shadow: 0 2px 4px 1px rgb(233 233 233 / 20%), inset 0 3px 6px 2px rgb(38 21 3 / 20%);
  background-color: rgb(0 0 0 / 3%);
  transition: box-shadow 0.3s ease-in-out;
}

@mixin interactionStyle() {
  & > div > a > span[class*='material-symbols-outlined'][class*='MeIcon'] {
    @include mixin.getFontWeightsWithFill();
  }

  &.IconRaisedHighlightBg {
    background: appProp.$orange100;
  }

  &.IconRaisedDangerBg {
    background-color: appProp.$danger100;
  }

  &.IconRaisedSuccessBg {
    background-color: appProp.$resultPassColorAlt;
  }

  &.IconRaisedUnselectedBg {
    background-color: appProp.$unselectedIconColor100;
  }
}

.IconRaised {
  @include mixin.getFlexDisplay(row, wrap, center, center);
  @include mixin.preventImageSelection();
  position: relative;
  border-radius: 0.35rem;
  padding: 0.075rem;
  align-self: center;
  transition: all 0.3s;
  aspect-ratio: 1 / 1;
  line-height: 1;

  & > div > a {
    @include mixin.getFlexDisplay(row, wrap, center, center);
  }

  & > div > a > span[class*='material-symbols-outlined'][class*='MeIcon'] {
    color: transparent;
    line-height: 1;
    border: 0.2765rem solid transparent;
    border-radius: 0.35rem;
    box-shadow: appProp.$blackBoxShadow35;
    transition: all 0.3s ease-in-out;

    &:not([class*='MeIcon_MeIconFilled']) {
      @include mixin.getFontWeightsWithoutFill();
    }

    &:is([class*='MeIcon_MeIconFilled']) {
      @include mixin.getFontWeightsWithFill();
    }
  }

  &HighlightBg {
    background: appProp.$orange90;
  }

  &DangerBg {
    background: appProp.$danger70;
  }

  &SuccessBg {
    background: appProp.$resultPassColorAlt70;
  }

  &UnselectedBg {
    background: appProp.$unselectedIconColor80;
  }

  &ActiveState {
    box-shadow: 0 2px 4px 1px rgb(233 233 233 / 20%), inset 0 2px 8px 2px rgb(38 21 3 / 60%);
    background-color: rgb(0 0 0 / 5%);
    transition: box-shadow 0.3s ease-in-out;
  }

  &:active:not([data-aria-disabled*='true']) {
    @include activeStateStyle();
  }
}

@media (hover) {
  .IconRaised:not([data-aria-disabled*='true']) {
    &:hover {
      @include hoverStateStyle();
      @include interactionStyle();
    }

    &:active {
      @include activeStateStyle();
      @include interactionStyle();
    }
  }
}
