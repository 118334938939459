@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.ImagePortalContainer {
  @include mixin.preventImageSelection();
  @include mixin.getFlexDisplay(column, nowrap, center, center, 1);
  @include mixin.getFillAvailableWidth();
  height: 100%;
  background-color: rgba(0 0 0 / 50%);

  &Wrapper {
    z-index: calc(appProp.$appMenuZ + 1);
    position: absolute;
    cursor: default;
    display: block;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100vh;
    transform: translate3d(-50%, -50%, 0);
  }
}