@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

$fontVarUnfilled: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48;
$fontVarFilled: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;

.ImageFrame {
  @include mixin.preventImageSelection();
  @include mixin.getFlexDisplay(column, nowrap, center, center);
  max-height: 30em;
  cursor: pointer;

  &Contents {
    position: relative;
    @include mixin.getFlexDisplay(column, nowrap, center, flex-start);
    @include mixin.preventImageSelection();
    @include mixin.getPopupWoodBg();
    @include mixin.getTransformStyle(flat);
    border: appProp.$thinWoodenBorder;
    border-radius: .5em;
    gap: .5em;
    font-variation-settings: $fontVarUnfilled;

    &::before {
      content: ' ';
      @include mixin.getFlexDisplay(column, unset, center, center, 1);
      position: absolute;
      border-radius: 0.25em;
      inset: 0.35em;
      box-shadow: appProp.$blackBoxShadow50;
    }

    &::after {
      content: ' ';
      position: absolute;
      @include mixin.getFlexDisplay(column, unset, center, center, 1);
      border-radius: 0.25em;
      inset: 0.55em;
      box-shadow: appProp.$cellShadow;
    }
  }
}

.FramedImage {
  height: 17em;
  border-radius: .25em;

  &Large {
    height: 45em;
    border-radius: .25em;
  }
}

@media (hover) {
  .ImageFrame:hover {
    filter: brightness(1.2);
    transform: scale(1.05);
    transition: transform .25s ease-in;
  }
}

.ImageFrame:active {
  filter: brightness(1.2) drop-shadow(0 0 4px black);
}

// #region KEYFRAMES ********************************************************************
@keyframes scale-up-center {
  0% {
    height: 17em;
  }

  100% {
    height: 45em;
  }
}

@keyframes scale-down-center {
  0% {
    height: 45em;
  }

  100% {
    height: 17em;
  }

}

// #endregion