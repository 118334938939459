@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

.ProgressIndicator {
  // @include mixin.getInsetPanel();
  @include mixin.getFlexDisplay(row, nowrap, center, flex-start, 1);
  user-select: none;
  box-shadow: appProp.$insetShadow;
  border-radius: 0.3125em;
  margin-bottom: 0.15em;
  width: 100%;
  padding: 0.5em;
  gap: 0.5em;
  position: relative;
  max-height: 3em;
  min-width: 3em;
  transition: all 1.5s ease-out;

  >h1 {
    min-width: fit-content;
  }

  &Bar {
    transition: all 1.5s ease-out;
    &::before {
      content: ' ';
      position: absolute;
      background-color: appProp.$primaryColor25;
      top: 0.6em;
      bottom: 0.4em;
      left: 0.5em;
      right: 0.5em;
      color: appProp.$primaryColor80;
      border: 0.125px groove transparent;
      border-radius: 0.1875em;
      box-shadow: appProp.$blackBoxShadow50;
    }
  }
}

@include mixin.getProgressBarWidthPercentages(ProgressIndicatorBar);