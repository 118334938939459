@use '/src/scss/variables.module.scss' as appProp;

.FloatingActionButton[class*="RoundedButton"] {
  position: fixed;
  backdrop-filter: blur(2px);
  bottom: 2em;
  z-index: appProp.$fabZ;
  opacity: 0;
  border-radius: 50%;
  transform: translateY(100em);
  aspect-ratio: 1 / 1;

  &.Show {
    animation: slide-in-bottom 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s 1 normal forwards;
  }

  &.Hide {
    animation: slide-out-bottom 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1 normal forwards;
  }

  &.Left {
    left: 0.5em;
    bottom: 0.5em;
  }

  &.Right {
    right: 2em;
  }
}


@keyframes slide-in-bottom {
  0% {
    transform: translateY(calc(100% + 10em));
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    transform: translateY(calc(100% - 2.5em));
    animation-timing-function: ease-in;
  }

  65% {
    transform: translateY(calc(100% - 3.5em));
    animation-timing-function: ease-in;
  }

  82% {
    transform: translateY(calc(100% - 4.2em));
    animation-timing-function: ease-in;
  }

  93% {
    transform: translateY(calc(100% - 5em));
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    transform: translateY(calc(100% - 4.5em));
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateY(calc(100% - 4em));
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes slide-out-bottom {
  0% {
    transform: translateY(calc(100% - 4em));
    animation-timing-function: ease-out;
    opacity: 1;
  }

  7% {
    transform: translateY(calc(100% - 5em));
    animation-timing-function: ease-in;
    opacity: 1;
  }

  18% {
    transform: translateY(calc(100% - 4.2em));
    animation-timing-function: ease-in;
    opacity: 1;
  }

  35% {
    transform: translateY(calc(100% - 3.5em));
    animation-timing-function: ease-in;
    opacity: 1;
  }

  60% {
    transform: translateY(calc(100% - 2.5em));
    animation-timing-function: ease-in;
    opacity: 1;
  }

  13%,
  25%,
  45%,
  75% {
    transform: translateY(calc(100% - 4.5em));
    animation-timing-function: ease-out;
    opacity: 1;
  }

  76% {
    opacity: 1;
  }

  100% {
    transform: translateY(calc(100% + 10em));
    animation-timing-function: ease-in;
    opacity: 1;
  }

}