@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Equation {
  @include mixin.getFlexDisplay(row, nowrap, center, center, 1);
  @include mixin.getFillAvailableWidth();

  &Side {

    &Right,
    &Left {
      @include mixin.getFlexDisplay(row, nowrap, center, center);
      gap: 1.5em;
      position: relative;

      >h1 {
        line-height: initial;
      }

      &.Highlight {
        outline: appProp.$extraThickWoodenBorder;
        outline-color: transparent;
        border-radius: 0.5em;
        padding: 0.5em 1.25em;

        &On {
          outline: appProp.$extraThickWoodenBorder;
        }

        &Off {
          outline-color: transparent;
        }
      }
    }
  }

  &Pulsate {
    animation: pulsate-fwd 0.5s ease-in-out infinite both;
  }

  // #region OPACITY ON HIGHLIGHT *******************************************************
  &.RightHighlight {
    .EquationSideLeft:not(.HighlightOn) {
      opacity: 0.6;
    }
  }

  &.LeftHighlight {
    .EquationSideRight:not(.HighlightOn) {
      opacity: 0.6;
    }
  }

  &.RightHighlight,
  &.LeftHighlight {
    .EqualSign:not(.EquationPulsate) {
      opacity: 0.6;
    }
  }

  // #endregion
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}