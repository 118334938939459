@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.DigitKeypad {
  @include mixin.getFlexDisplay(row, wrap, center, center);
  gap: 0.5em;
  max-width: 21.5em;
  padding: 0.5em;
  border-radius: 0.25em;
  box-shadow: appProp.$insetStyle;

  &Row {
    @include mixin.getFlexDisplay(row, nowrap, center, center);
    gap: 0.5em;
  }
}