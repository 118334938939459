@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;
@use '/src/scss/animations.module.scss' as appAnimation;

.Icon {
  color: appProp.$woodColor90;

  &.UnselectedColor[class*="material-symbols-outlined"] {
    color: appProp.$unselectedIconColor;
  }

  &[class*="material-symbols-outlined"] {
    @include mixin.getFontVariationSettings(0, 600);
    font-size: 1.5em;
  }

  &Filled {
    &[class*="material-symbols-outlined"][class*="Icon"] {
      @include mixin.getFontVariationSettings(1, 600);
    }
  }

  &[class*='xs12'] {
    width: 1em;
    height: 1em;
    font-size: 1.2em;
    vertical-align: bottom;
    @include mixin.getFontVariationSettings(0, 700, 200, 24);
  }

  @include mixin.getElementFontSizes();
}

.UsePointerCursor {
  cursor: pointer;
}

.DisabledButton {
  cursor: not-allowed;
}

.DangerBg {
  background-color: appProp.$dangerColor;
}

.SuccessBg {
  background-color: appProp.$successBtnColor;
}

.InsetText {
  align-items: center;
  gap: 0.2em;

  >span[class*="material-symbols-outlined"] {
    color: transparent;
  }
}

.TomatoIcon[class*='RedTomato'] {
  filter: drop-shadow(0 0 1px #453831);
}

.TomatoIcon[class*='GreenTomato'] {
  filter: drop-shadow(0 0 1px #453831) hue-rotate(90deg);
}

.GreenTomatoIcon {
  @extend .TomatoIcon;
  filter: drop-shadow(0 0 1px #453831) hue-rotate(90deg);
}

.RedTomatoIcon {
  @extend .TomatoIcon;
  filter: drop-shadow(0 0 1px #453831) hue-rotate(27deg);
}