@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

.Controls {
  @include mixin.getFlexDisplay(row, nowrap, center, center, 1);
  gap: 2.5em;
  position: relative;
  // z-index: calc(appProp.$debugPanelZ + 1);

  &Item {
    @include mixin.getRaisedPanel();
    padding: 0.25em 1em;
  }
}