@use '/src/scss/mixins.module.scss' as mixin;
@use '/src/scss/variables.module.scss' as appProp;

%CornerBannerSize {
  min-width: 10em;
  max-width: 10em;
}
%CornerBannerSizeSmall {
  min-width: 5em;
  max-width: 7em;
}

%HorizontalBannerSize {
  min-width: 110%;
  left: -0.5em;
  right: -0.5em;
}

.Banner {
  position: absolute;
  @include mixin.getFlexDisplay(row, wrap, center, center);
  @include mixin.getFillAvailableWidth();
  @include mixin.getRaisedPanel();
  padding: 0.15em 0;
  min-height: 1.5em;
  overflow: hidden;

  // #region BANNER COLORS
  &.Primary {
    background: appProp.$orangeEnabled;
  }

  &.Success {
    background: appProp.$resultPassColor;
  }

  &.Info {
    background: appProp.$lightwoodColor;
    backdrop-filter: blur(15px);
  }

  &.Warning {
    background: appProp.$lightgoldColor;
    backdrop-filter: blur(15px);
  }

  &.Error {
    background: appProp.$danger100;
  }

  &.Private {
    background: appProp.$lemonColor50;
  }

  // #endregion

  // #region BANNER POSITIONS
  &UnlockRequired {
    justify-content: flex-end;
    text-align: end;
    bottom: 0.5em;
    left: 0;
    padding-right: 24px;
  }

  &.Position {
    &TopRight {
      @extend %CornerBannerSize;
      rotate: 45deg;
      right: -3em;
      top: 1.5em;

      &[class*='Small'] {
        @extend %CornerBannerSizeSmall;
        top: 0.8em;
        right: -2em;
      }
    }

    &TopLeft {
      @extend %CornerBannerSize;
      rotate: -45deg;
      top: 1.5em;
      left: -3em;

      &[class*='Small'] {
        @extend %CornerBannerSizeSmall;
        top: 0.8em;
        left: -2em;
      }
    }

    &BottomRight {
      @extend %CornerBannerSize;
      rotate: -45deg;
      bottom: 1.5em;
      right: -3em;

      &[class*='Small'] {
        @extend %CornerBannerSizeSmall;
        rotate: -45deg;
        bottom: 0.8em;
        right: -2em;
      }
    }

    &BottomLeft {
      @extend %CornerBannerSize;
      rotate: 45deg;
      bottom: 1.5em;
      left: -3em;
    }

    &HorizontalTop {
      @extend %HorizontalBannerSize;
      top: 0.5em;
    }

    &HorizontalBottom {
      @extend %HorizontalBannerSize;
      bottom: 0.5em;
    }
  }

  // #endregion
}
